import React from 'react'
import GapInput from './GapInput';
import { LoadingState, Gap } from '../../../types';
import { useGaps } from '../../../hooks';
import Skeleton from '@material-ui/lab/Skeleton';

const GapLoader: React.FC<{ gap: LoadingState<Gap>, className?: string }> = (props) => {
    const { gap } = props
    const { deleteGap, gaps } = useGaps();

    if (gap.isLoading || gap.error) {
        return <>
            <Skeleton variant="rect" width={'100%'} height={200} />
        </>
    } else {
        return <GapInput clientId={gap.clientId} gap={gap.data} onDelete={gaps.length > 1 ? () => deleteGap(gap.clientId) : undefined} />
    } // we should remove the skeleton for deleting
}

export default GapLoader