import React from 'react';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core'

interface ComponentProps {
    title?: string
}

const gradientPAIcool = {
    background: 'linear-gradient(45deg, #1f2891 30%, #2b95bd 60%, #daf3c9 90%)',
    overflow: 'hidden',
  }
const gradientPAImini = {
    background: 'linear-gradient(60deg, #1f2891 15%, #2b95bd 75%, #daf3c9 100%)',
    overflow: 'hidden',
}


const useStyles = makeStyles(theme => ({
    backgroundGradient: gradientPAIcool,
    [theme.breakpoints.down('sm')]: {
        backgroundGradient: gradientPAImini,
    },
    container: {
        padding: theme.spacing(0,2.5),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    title: {
        minWidth: theme.breakpoints.values.md,
        margin: '0 auto',
        padding: theme.spacing(0),
        [theme.breakpoints.down('sm')]: {
            padding: 0
        },
    },
}))

type Props = ComponentProps


const BasePage: React.FC<Props> = (ComponentProps) => {
    const { children } = ComponentProps;
    const classes = useStyles();

    return <>
       {/* <AppBar position="sticky">
            <Toolbar variant="dense" className={classes.backgroundGradient}>
                <Typography className={classes.title} variant="h6">{title}</Typography>
            </Toolbar>
        </AppBar> */}
        <Container className={classes.container}>
            {children}
        </Container>
    </>
}

export default BasePage
