import { useEffect, useState } from "react";
import fetchJson from "../utils/data";
import { PlayerInfo, LoadingState } from "../types";
import uuidv4 from 'uuid/v4'

export function usePlayer() {
    const [player, setPlayer] = useState<LoadingState<PlayerInfo>>({
        clientId: uuidv4(),
        isLoading: true,
        data: null,
        error: null
    })

    useEffect(() => {
        async function fetchPlayer() {
            console.log("Fetching player")
            const playerInfo = await fetchJson<PlayerInfo>('/api/playerInfo', 'POST', {})
            console.log("Fetched player! " + playerInfo.id)
            setPlayer({
                clientId: player.clientId,
                isLoading: false,
                error: null,
                data: playerInfo
            })
        }

        fetchPlayer();
    }, [player.clientId])

    return {
        player
    };
}