import React, { useContext } from 'react'
import { Typography, makeStyles, useMediaQuery, useTheme, Link, } from '@material-ui/core'
import Counter from '../Counter';
import { GlobalDataContext } from '../../../context/GlobalDataContext';
import Skeleton from '@material-ui/lab/Skeleton';
import desktopTimeline from '../img/timeline_desktop.png';
import mobileTimeline from '../img/timeline_mobile.png'


const useStyles = makeStyles(theme => ({
    textBlock: {
        margin: theme.spacing(2, 0, 3),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1, 0, 2),
        },
    },
    emBlock: {
        margin: theme.spacing(2, 0),
        fontWeight: 600,
    },
    listBlock: {
        margin: theme.spacing(1, 0),
    },
    subtextTitle: {
        margin: theme.spacing(1, 0),
        color: '#595959',
        fontWeight: 600,
    },
    subtextBlock: {
        margin: theme.spacing(1, 0),
        color: '#595959',
    },
    horizontalLine: {
        margin: theme.spacing(1, 0, 3),
        border: '.5px solid #C4C4C4',
    },
    a: { textDecoration: 'none', },
    listFormat: {
        margin: theme.spacing(0,0,0,2),
        '& > *': {
            fontSize: '1rem',
            marginBottom: theme.spacing(1.5),
            fontWeight: 600,
        },
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(0),
        },
    },
    timeline: {
        width: '100%',
        margin: theme.spacing(1,0),
        [theme.breakpoints.down('sm')]: {
            /* This could definitely be done in a nicer way, but too bad */
            maxWidth: '25rem',
            display: 'block',
            margin: '0 auto',
        },
    },

}))

const WelcomePage: React.FC = () => {
    const classes = useStyles();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { counts } = useContext(GlobalDataContext)

    const counter = counts ?
        <Counter 
            counts={[
                {
                    label: 'Gaps',
                    value: counts.gaps
                },
                {
                    label: 'Solutions',
                    value: counts.solutions
                },
                {
                    label: 'Scores',
                    value: counts.scores
                }
            ]}
        /> : <>
            <Skeleton height="24px" />
            <Skeleton height="24px" />
            <Skeleton height="24px" />
        </>

    return <div>
        <Typography className={classes.textBlock}>
            As companies develop AI principles in the pursuit of responsible AI, a persistent challenge remains in bridging the gap between these principles and day-to-day practice. Closing Gaps in Responsible AI is an initiative to crowdsource issues in the implementation of responsible AI.
        </Typography>
        <img className={classes.timeline} alt="Timeline from kickoff in Fall 2019 to final publication and open sourcing in Summer 2020" src={mobile ? mobileTimeline : desktopTimeline}></img>
        <Typography className={classes.textBlock}>
            With this Closing Gaps Ideation, you’ll have the opportunity to collaborate with others in the AI community to identify challenges and discover practical solutions.
        </Typography>
        {!mobile && counter}
        {mobile && counter}
        <Typography className={classes.textBlock}>
            While interacting with ideas and submissions from other players, you’re invited to:
        </Typography>
        <ul className={classes.listFormat}> {/*Needs some formatting love, but leaving it for now*/}
            <li>suggest “gaps” for the community to address</li>
            <li>identify and propose solutions</li>
            <li>score community solutions for feasibility and impact</li>
        </ul>
        <Typography className={classes.textBlock}>
            This ideation is anonymous, and we invite anyone who is actively working to make AI more responsible to participate. You are welcome to repeat this experience as many times as you’d like.
        </Typography>
        <Typography className={classes.textBlock}>
            In order to maintain anonymity, please do not include identifying information in your responses. As a precautionary measure, all submissions will also be moderated before being shared back to the community.
        </Typography>
        <div className={classes.horizontalLine}></div>
        <Typography variant="body1" component="h2" className={classes.subtextTitle}>
            About Closing Gaps in Responsible AI & the Partnership on AI
        </Typography>
        <Typography className={classes.subtextBlock}>
            The <Link className={classes.a} href="https://www.partnershiponai.org/" target="_blank" rel="noopener">Partnership on AI</Link> (PAI) is a global multistakeholder nonprofit committed to the creation and dissemination of best practices in artificial intelligence through the diversity of its Partners. By gathering the leading companies, organizations, and people differently affected by artificial intelligence, PAI establishes a common ground between entities which otherwise may not have cause to work together and&mdash;in so doing&mdash;serves as a uniting force for good in the AI ecosystem. Today, PAI convenes 100 partner organizations from around the world to realize the promise of artificial intelligence.
        </Typography>
        <Typography className={classes.subtextBlock}>
            The Closing Gaps Ideation is the first phase of <Link className={classes.a} href="https://www.partnershiponai.org/pai-launches-interactive-project-to-put-ethical-ai-principles-into-practice/" target="_blank" rel="noopener">Closing Gaps in Responsible AI</Link>, a multiphase, multi-stakeholder project initiated by PAI, aimed at surfacing the collective wisdom of the community to identify salient challenges and evaluate potential solutions.
        </Typography>
    </div>
}

export default WelcomePage;