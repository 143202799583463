import React, { useCallback, useContext } from 'react'
import { Typography, makeStyles, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Box } from '@material-ui/core'
import { useMultipleChoice } from '../../../hooks/useMultipleChoice'
import Heatmap from './Heatmap'
import { GlobalDataContext } from '../../../context/GlobalDataContext'

const useStyles = makeStyles(theme => ({
    textBlock: {
        margin: theme.spacing(2, 0, 3),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1, 0, 2),
        },
    },
}))

type MultipleChoice = {
    id: string,
    text: string,
    options: string[]
}
const MultipleChoice: React.FC<MultipleChoice> = (props) => {
    const { id, text, options } = props
    const { setMultipleChoiceAnswer } = useMultipleChoice(id);
    const handleChange = useCallback((e) => {
        setMultipleChoiceAnswer(e.target.value)
    }, [setMultipleChoiceAnswer])
    return <Box marginBottom={2} marginTop={2}>
        <FormControl component="fieldset">
            <FormLabel>{text}</FormLabel> {/* I'd like to be able to add some space, but haven't figured out how */}
            <RadioGroup name={id} onChange={handleChange}>
                {options.map((option, idx) => <FormControlLabel
                    key={`${id}-${idx}`}
                    value={option}
                    control={<Radio />}
                    label={option}
                />)}
            </RadioGroup>
        </FormControl>
    </Box>
}

const PlayerSurvey: React.FC = () => {
    const { heatmap } = useContext(GlobalDataContext)
    const classes = useStyles();

    return <>
        <Typography className={classes.textBlock}>
            Terrific! Here is a heatmap showing how all of the proposed solutions so far have been scored on impact vs feasibility:
        </Typography>
        {heatmap && <Heatmap data={heatmap} />}
        <Typography className={classes.textBlock}>
            Please share some information about yourself and your organization to help us better understand the responsible AI community.
        </Typography>
        <MultipleChoice 
            id="MultipleChoice1"
            text={`Which of these best describes the nature of the organization you work for?`}
            options={[
                'For-profit',
                'Nonprofit',
                'Government',
                'Academic',
                'Independent or unaffiliated'
            ]}
        />
        <MultipleChoice 
            id="MultipleChoice3"
            text={`How many people work in your organization?`}
            options={[
                '2 to 10',
                '21 to 100',
                '101 to 1,000',
                '1,001 to 50,000',
                'over 50,000',
                'Independent or unaffiliated'
            ]}
        />
        <MultipleChoice 
            id="MultipleChoice4"
            text={`Which of these best describes the primary responsibilities of your role?`}
            options={[
                'Strategy', 'Managing people', 'Managing projects or products', 'Developing products, services, or tools', 'Research', 'Other'
            ]}
        />
        <MultipleChoice 
            id="MultipleChoice2"
            text={`In what region is your organization head quartered?`}
            options={[
                'Sub-Saharan Africa',
                'South Asia',
                'South America',
                'Russia',
                'North America',
                'Middle East and North Africa',
                'Europe',
                'East Asia',
                'Central Asia',
                'Central America and Caribbean',
                'Australia and Oceania',
            ]}
        />
    </>
}

export default PlayerSurvey