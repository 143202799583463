import { useSurvey } from "../context/SurveyContext";
import { useEffect, useCallback } from "react";
import fetchJson from "../utils/data";
import { Gap } from "../types";
import uuidv4 from 'uuid/v4'
import { debounce } from "debounce";

const updateGap = debounce((playerId: string, gap: Gap) => {
    fetchJson(`/api/gaps/?playerId=${playerId}`, 'PATCH', {
        ...gap
    })
}, 200)

export function useGaps({ validOnly }: { validOnly: boolean } = { validOnly: false }) {
    const survey = useSurvey();
    const dispatch = survey.dispatch

    const addGap = useCallback(async () => {
        const clientId = uuidv4()
        dispatch({
            type: 'ADD_GAP',
            payload: {
                clientId,
                isLoading: true,
                error: null,
                data: null
            }
        })

        const gap = await fetchJson<Gap>(`/api/gaps?playerId=${survey.player.id}`, 'POST', {
            fields: {
                Need: '',
                Challenge: ''
            }
        } as Partial<Gap>)

        dispatch({
            type: 'UPDATE',
            payload: {
                clientId,
                isLoading: false,
                error: null,
                data: gap
            }
        })
    }, [survey.player.id, dispatch])

    const updateFields = useCallback(async (clientId: string, fields: Partial<Gap['fields']>) => {
        const existingGap = survey.gaps.find(gap => gap.clientId === clientId);
        if (!existingGap || existingGap.isLoading || !existingGap.data) {
            throw new Error("Gap doesn't exist????")
        }

        const data = {
            ...existingGap.data,
            fields: {
                ...existingGap.data.fields,
                ...fields
            }
        }

        dispatch({
            type: 'UPDATE',
            payload: {
                ...existingGap,
                data
            }
        })

        updateGap(survey.player.id, {
            ...existingGap.data,
            ...data
        })
    }, [dispatch, survey.gaps, survey.player.id])

    const deleteGap = useCallback(async (clientId: string) => {
        const gapToDelete = survey.gaps.find(gap => gap.clientId === clientId)
        if (!gapToDelete) {
            return
        }

        if (gapToDelete.data) {
            dispatch({
                type: 'UPDATE',
                payload: {
                    clientId,
                    isLoading: true,
                    error: null,
                    data: null
                }
            })

            await fetchJson<Gap>(`/api/gaps/${gapToDelete.data.id}?playerId=${survey.player.id}`, 'DELETE', {})

            dispatch({
                type: 'DELETE',
                clientId
            })
        }
    }, [dispatch, survey.gaps, survey.player.id])

    // create the first gap
    useEffect(() => {
        if (survey.gaps.length === 0) {
            addGap()
        }
    }, [addGap, survey.gaps.length])

    const gaps = survey.gaps.filter(gap => !validOnly || (!!gap.data?.fields.Need && !!gap.data?.fields.Challenge))

    return {
        gaps,
        addGap, deleteGap, updateFields
    }
}