import React, { useContext } from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import WordCloud from './wordCloud'
import { useGaps } from '../../../hooks';
import SolutionGroup from './SolutionGroup'
import { useSolutions } from '../../../hooks/solutionHooks'
import { GlobalDataContext } from '../../../context/GlobalDataContext';

const useStyles = makeStyles(theme => ({
    textBlock: {
        margin: theme.spacing(2, 0, 3),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1, 0, 2),
        },
    },
    gapPrompt: {
        margin: theme.spacing(2, 0, 1),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1, 0, 1),
        }, 
    },
}))

const Instructions: React.FC = () => {
    const classes = useStyles();
    const { unsolvedGaps, solutions } = useSolutions()
    const { counts } = useContext(GlobalDataContext);
    const { gaps } = useGaps()
    let currentGaps = gaps.length + (counts?.gaps || 0)

    if (!unsolvedGaps || unsolvedGaps.isLoading) {
        return <div>Loading...</div> // TODO: better loading UI
    }
    return <div>
        <Typography className={classes.textBlock}>
            With your help, we’ve collectively identified <strong>{currentGaps}</strong> gaps. Here is a wordcloud of the gaps we’ve identified together:
        </Typography>
        <WordCloud />
        <Typography className={classes.textBlock}>
            Let’s start to close these gaps with ideas for resources, tools, or actions that address the challenges we’ve outlined together.
        </Typography>
        <Typography className={classes.textBlock}>
            You’ll be shown three gaps that other players have identified as needs and challenges in the journey to responsible AI. For each gap, add as many ideas as you can for possible solutions to help close that gap. Solutions could be actual or aspirational - real things that you know exist already, or ideas that really ought to exist.
        </Typography>
        <Typography className={classes.textBlock}>
            This process is about surfacing the widest possible range of ideas before we narrow in. Remember, submissions are anonymous so feel free to be outrageous and dream big&mdash;but do be specific! Feel free to include links or references to existing materials in your solutions.
        </Typography>
        <Typography className={classes.gapPrompt}>
            The first gap is...
        </Typography>
        {unsolvedGaps.isLoading === false && unsolvedGaps.error === null && <SolutionGroup unsolvedGap={unsolvedGaps.data[0]} solutions={solutions.filter(sol => sol.clientGapId === unsolvedGaps.data[0].id)} />}
    </div>
}

export default Instructions;