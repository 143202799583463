import React, {  } from 'react'
import { makeStyles, Button } from '@material-ui/core'
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import EmailIcon from '@material-ui/icons/Email';

interface Props {

}

const useStyles = makeStyles(theme => ({
    resp_sharing_button_link: {
        textDecoration: "none",
        color: "#ffffff",
        margin: theme.spacing(.5,1),
    },
    twitter: {
        backgroundColor: "#55acee",
        '&:hover': {backgroundColor: "#468dc3",},
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1.5),
            minWidth: theme.spacing(4),
        },
        borderRadius: '1.5rem',
    },
    linkedIn: {
        backgroundColor: "#046293",
        '&:hover': {backgroundColor: "#034668",},
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1.5),
            minWidth: theme.spacing(4),
        },
        borderRadius: '1.5rem',
    },
    facebook: {
        backgroundColor: "#2d4373",
        '&:hover': {backgroundColor: "#1c2a48",},
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1.5),
            minWidth: theme.spacing(4),
        },
        borderRadius: '1.5rem',
    },
    email: {
        backgroundColor: "#606060",
        '&:hover': {backgroundColor: "#3e3e3e",},
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1.5),
            minWidth: theme.spacing(4),
        },
        borderRadius: '1.5rem',
    },
}))


const SocialSharing: React.FC<Props> = () => {
    const classes = useStyles();
  
    return <>
        {/* Sharingbutton Twitter */}
        <a className="resp_sharing_button_link" href="https://twitter.com/intent/tweet/?text=%20Help%20put%20ethical%20tech%20principles%20to%20work%20in%20organizational%20practice!&url=https%3A%2F%2Fclosing-gaps.partnershiponai.org%2F" target="_blank" rel="noopener noreferrer" aria-label={"Twitter sharing"}>
            <Button variant="contained" className={classes.twitter}>
                <TwitterIcon htmlColor="#ffffff" />
            </Button>
        </a>
        {/* Sharingbutton LinkedIn */}
        <a className="resp_sharing_button_link" href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fclosing-gaps.partnershiponai.org%2F&amp;title=%20Help%20put%20ethical%20tech%20principles%20to%20work%20in%20organizational%20practice!&amp;summary=%20Help%20put%20ethical%20tech%20principles%20to%20work%20in%20organizational%20practice!&amp;source=https%3A%2F%2Fclosing-gaps.partnershiponai.org%2F" target="_blank" rel="noopener noreferrer" aria-label={"LinkedIn sharing"}>
            <Button variant="contained" className={classes.linkedIn}>
                <LinkedInIcon htmlColor="#ffffff" />
            </Button>
        </a>
        {/* Sharingbutton Facebook */}
        <a className="resp_sharing_button_link" href="https://facebook.com/sharer/sharer.php?u=https%3A%2F%2Fclosing-gaps.partnershiponai.org%2F" target="_blank" rel="noopener noreferrer" aria-label={"Facebook sharing"}>
            <Button variant="contained" className={classes.facebook}>
                <FacebookIcon htmlColor="#ffffff" />
            </Button>
        </a>
        {/* Sharingbutton E-Mail */}
        <a className="resp_sharing_button_link" href="mailto:?subject=%20Help%20put%20ethical%20tech%20principles%20to%20work%20in%20organizational%20practice!&amp;body=https%3A%2F%2Fclosing-gaps.partnershiponai.org%2F" target="_blank" rel="noopener noreferrer" aria-label={"Email sharing"}>
            <Button variant="contained" className={classes.email}>
                <EmailIcon htmlColor="#ffffff" />
            </Button> 
        </a>
</>
};
export default SocialSharing;