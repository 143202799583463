import React from "react"
import ReactSimpleHeatmap from "react-simple-heatmap"
import { useTheme, makeStyles, Typography, Box,  } from "@material-ui/core"
import Prioritization35 from "../img/prioritization35.png"
import Prioritization33 from "../img/prioritization33.png"
import StarIcon from '@material-ui/icons/Star';

type Props = {
  data: number[][]
}

const useStyles = makeStyles(theme => ({
  heatmap: {
    margin: '-1rem auto 0',
    height: '300px',
    width: '500px',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vmin - 30px)',
      width: 'calc(100vmin - 30px)',
      maxWidth: '350px',
      maxHeight: '350px',
      margin: '1rem 0',
    },
    position: 'relative',
    boxShadow: '2px 3px 5px lightgrey',
  },
  mapOverlay: {
    position: 'absolute',
    backgroundImage: `url(${Prioritization35})`,
    backgroundSize: 'cover',
    height: '300px',
    width: '500px',
    [theme.breakpoints.down('sm')]: {
      height: '350px',
      width: '350px',
      backgroundImage: `url(${Prioritization33})`,
    },
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vmin - 30px)',
      width: 'calc(100vmin - 30px)',
      maxWidth: '350px',
      maxHeight: '350px',
      backgroundImage: `url(${Prioritization33})`,
    },
  },
  impactAxis: {
    position: 'absolute',
    color: '#FFFFFF',
    bottom: theme.spacing(15),
    left: theme.spacing(-1),
    fontSize: '1.25rem',
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(15),
      left: theme.spacing(-1),
      fontSize: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(13),
      left: theme.spacing(-2),
      fontSize: '1rem',
    },
    transform: 'rotate(90deg)',
  },
  feasibilityAxis: {
    position: 'absolute',
    color: '#FFFFFF',
    bottom: theme.spacing(2),
    left: theme.spacing(10),
    fontSize: '1.25rem',
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(2),
      left: theme.spacing(10),
      fontSize: '1rem',
    },
    [theme.breakpoints.down('xs')]: {
      bottom: theme.spacing(1),
      left: theme.spacing(9),
      fontSize: '1rem',
    },
  },
  bigBets: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(19),
    [theme.breakpoints.down('sm')]: {
      top: theme.spacing(2),
      left: theme.spacing(12),
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    fontFamily: 'Roboto Mono, monospace',
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '1.1rem',
    color: theme.palette.primary.contrastText,
  },
  utilities: {
    position: 'absolute',
    bottom: theme.spacing(12),
    right: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(15),
      right: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    fontFamily: 'Roboto Mono, monospace',
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '1.1rem',
    color: theme.palette.primary.contrastText,
  },
  noBrainers: {
    position: 'absolute',
    top: theme.spacing(6),
    right: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      top: theme.spacing(7),
      right: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    fontFamily: 'Roboto Mono, monospace',
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '1.1rem',
    color: theme.palette.primary.contrastText,
  },
  star: {
    position: 'absolute',
    color: '#FFFFFF',
    top: theme.spacing(3),
    right: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  legend: {
    margin: '.75rem auto 0',
    width: '500px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '350px',
      width: '90%',
    },
    display: 'flex',
    background: 'linear-gradient(90deg, #2B2C7B, #D8229B)',
    justifyContent: 'space-between',
  },
  legendLabels: {
    fontFamily: 'Roboto Mono, monospace',
    textTransform: 'uppercase',
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(.75, 2),
  },
}))

const Heatmap: React.FC<Props> = ({ data }) => {
  const theme = useTheme()
  const classes = useStyles();

  return <>
    <div className={classes.heatmap}>
    <div className={classes.mapOverlay}></div>
    <Typography className={classes.impactAxis}>&#x27F5; Impact</Typography>
    <Typography className={classes.feasibilityAxis}>Feasibility &#x27F6;</Typography>
    <Typography className={classes.noBrainers}>No-Brainers</Typography>
    <Typography className={classes.bigBets}>Big Bets</Typography>
    <Typography className={classes.utilities}>Utilities</Typography>
    {theme.breakpoints.down('xs') && <StarIcon className={classes.star}/> }
    <ReactSimpleHeatmap
      bgColors={[hexToRgb('#DB219D'), hexToRgb(theme.palette.primary.main)]} // high to low
      data={data}
      bordered={false}
      //showLegend={true}
      //legendStyle={{display:'none',}}
      showTicks={false}
    />
    </div>
    <Box className={classes.legend}>
    <Typography className={classes.legendLabels}>Few</Typography>
    <Typography className={classes.legendLabels}>Many</Typography>
    </Box>
  </>
}

function hexToRgb(hex: string) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const rgb = result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;

  return rgb && `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`
}

export default Heatmap