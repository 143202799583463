import React from 'react';
import Survey from './components/Survey'
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import BasePage from './components/BasePage';
import theme from './theme';
import WelcomePage from './components/Survey/WelcomePage';
import SpottingGaps from './components/Survey/SpottingGaps';
import Solutioning from './components/Survey/Solutioning';
import Scoring from './components/Survey/Scoring';
import Finish from './components/Survey/Finish';
import { SurveyProvider } from './context/SurveyContext';
import { usePlayer } from './hooks';
import PlayerSurvey from './components/Survey/PlayerSurvey';
import { GlobalDataContextProvider, useGlobalData } from './context/GlobalDataContext';


const App: React.FC = () => {
  const { player } = usePlayer();
  const globalDataContext = useGlobalData();

  return <ThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
      <Switch>
        <Redirect from="/" to="/game" exact={true} />
        <Redirect from="/game" to="/game/intro" exact={true} />
        <Route path="/game/:step/:substep?">
          <Survey>
            <GlobalDataContextProvider value={globalDataContext}>
              <Route path="/game/intro">
                <WelcomePage />
              </Route>
              {player.isLoading || player.error != null || !globalDataContext.ready ?
                <>Loading...</> :
                <SurveyProvider player={player.data}>
                  <Switch>
                    <Route path="/game/spotting-gaps">
                      <SpottingGaps />
                    </Route>
                    <Route path="/game/prioritization">
                    </Route>
                    <Route path="/game/solutioning">
                      <Solutioning />
                    </Route>
                    <Route path="/game/scoring">
                      <Scoring />
                    </Route>
                    <Route path="/game/survey">
                      <PlayerSurvey />
                    </Route>
                    <Route path="/game/finish">
                      <Finish />
                    </Route>
                  </Switch>
                </SurveyProvider>
              }
            </GlobalDataContextProvider>
          </Survey>
        </Route>
        <Route>
          <BasePage />
        </Route>
      </Switch>
    </Router>
  </ThemeProvider>
}

export default App;
