import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import { responsiveFontSizes, createMuiTheme } from "@material-ui/core";

const palettePAI: PaletteOptions = {
  primary: {
    //main: '#3c4fdd' // electric blue
    main: '#2B2C7B', // dark purply blue
    contrastText: 'white',
  },
  secondary: {
    //main: '#2295bc', // teal
    contrastText: 'white',
    main: '#842387', // purpleish, not from PAI palette but darkened
    light: '#C84194', // pink, not from PAI palette but darkened barely
    /*colors: [
      '#2B2C7B', // theme.palette.primary.main dark blue
      '#842387', // theme.palette.secondary.main purple
      '#CA3F54', // peachy, not from PAI palette but darkened
      '#4C5CA6', // lavendar purpleish from PAI palette
      '#C84194', // pink, not from PAI palette but darkened barely
      '#0C7CAD', // teal, not from PAI palette but darkened
      '#3c4fdd', // electric blue
      '#DBEAC7', // luna moth from PAI palette
    ],*/
    //NEWwordcloudColors: ['#842387', '#CA3F54', '#C84194', '#2B2C7B', '#4C5CA6',],
    // OLDwordcloudColors: [ '#892a8d', '#3c4fdd', '#2295bc', '#ec1fba', '#db7769', '#0f1385'], // tried to use a mix of official colors, but it 
  }
}

const theme = responsiveFontSizes(createMuiTheme({
  palette: palettePAI,
  typography: {
    fontFamily: [
      'Avenir Next',
      'Avenir',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    subtitle1: {
      textTransform: 'lowercase',
    },
    subtitle2: {
      fontFamily: 'Roboto Mono, monospace',
      textTransform: 'uppercase',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 400,
      md: 750,
      lg: 960,
      xl: 1280,
    }
  }
}));
export default theme;