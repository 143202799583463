import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton';
import { ClientGapScore } from '../../../context/SurveyContext';
import GapDisplay from '../../GapDisplay';
import { useGapScores } from '../../../hooks/gapScoreHooks';
import { makeStyles, Typography, Box } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';


const useStyles = makeStyles(theme => ({
    ratingField: {
        color: theme.palette.secondary.light,
    },
    ratingGroup: {
        marginBottom: theme.spacing(2),
        display: 'flex',
        flexWrap: 'wrap',
    },
}))

const GapScore: React.FC<{ gapScore: ClientGapScore, className?: string }> = (props) => {
    const { gapScore } = props
    const classes = useStyles(props)
    const { gapsForGapScoring, updateFields } = useGapScores();

    if (gapScore.isLoading || gapScore.error || gapsForGapScoring?.isLoading || gapsForGapScoring?.error) {
        return <>
            <Skeleton variant="rect" width={'100%'} height={200} />
        </>
    } else if (gapsForGapScoring) {
        const gap = gapsForGapScoring.data.find(g => g.id === gapScore.clientGapId)!
        return <>
            <GapDisplay gap={gap} />
            <Box className={classes.ratingGroup} display="flex">
                <Box marginRight={7.5} marginTop={0.5}>
                    <Typography>Relatable</Typography>
                    <Rating
                        name={`ranking-gap-relatable-${gap.id}`}
                        value={gapScore?.data?.fields.Relatable || 0}
                        size="large"
                        className={classes.ratingField}
                        onChange={(e, val) => updateFields(gapScore.clientId!, gap.id, {
                            Relatable: val!
                        })}
                    />
                </Box>
                <Box marginTop={0.5}>
                    <Typography>Important</Typography>
                    <Rating
                        name={`ranking-gap-important-${gap.id}`}
                        value={gapScore?.data?.fields.Important || 0}
                        size="large"
                        className={classes.ratingField}
                        onChange={(e, val) => updateFields(gapScore.clientId!, gap.id, {
                            Important: val!
                        })}
                    />
                </Box>
            </Box>
        </>
    } else {
        return <></>
    }
}

export default GapScore