import React, { useCallback, useState } from 'react';
import { TextField, Typography, makeStyles, Button } from '@material-ui/core';
import clsx from 'clsx';
import DeleteIcon from '@material-ui/icons/Delete';
import { Gap } from '../../../types';
import { useGaps } from '../../../hooks';
import fetchJson from '../../../utils/data';

const useStyles = makeStyles(theme => ({
    root: {
    },
    fieldSet: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            flexDirection: 'column',
        },
    },
    plainText: {
        margin: '.5rem 0',
    },
    needField: {
        color: theme.palette.secondary.main,
        flex: '8',
        background: theme.palette.background.paper,
    },
    challengeField: { 
        color: theme.palette.primary.main,
        background: theme.palette.background.paper,
        flex: '8',
    },
    randomNeedButton: {
        marginLeft: theme.spacing(1),
        fontFamily: 'Roboto Mono, monospace',
        textTransform: 'uppercase',
        [theme.breakpoints.down('sm')]: {
            margin: 0,
        },
    },
    randomChallengeButton: {
        marginLeft: theme.spacing(1),
        fontFamily: 'Roboto Mono, monospace',
        textTransform: 'uppercase',
        [theme.breakpoints.down('sm')]: {
            margin: 0,
        },
    },
    deleteButton: {
    },
}))

type Props = {
    onDelete?: () => void
    clientId: string
    gap: Gap
}

const GapInput: React.FC<Props> = (props) => {
    const { onDelete, gap, clientId } = props;
    const { updateFields } = useGaps()
    const [ loading, setLoading ] = useState(false)
    const classes = useStyles(props)
    const randomize = useCallback(async (type: 'Need' | 'Challenge') => {
        try {
            setLoading(true)
            const gaps = await fetchJson<Gap[]>(`/api/randomGap?amount=${1}`, 'GET');
            updateFields(clientId, { [type]: gaps[0].fields[type] })
        } finally {
            setLoading(false)
        }
    }, [clientId, updateFields])

    return <div className={clsx(classes.root)}>
        <Typography className={classes.plainText}>To operationalize responsible AI...</Typography>
        <div className={classes.fieldSet}>
            <TextField value={gap.fields.Need || ''} onChange={(e) => updateFields(clientId, { Need: e.target.value })} label="Enter need" variant="outlined" classes={{ root: classes.needField }} /> {/* should add a max length and possibly validation. 250 chars?*/}
            <Button
                //disabled={!onDelete}
                className={classes.randomNeedButton}
                variant={'outlined'}
                //endIcon={<CachedIcon />}
                disabled={loading}
                size={'small'}
                color="secondary"
                onClick={() => randomize('Need')}
            >Random</Button>
        </div>
        <Typography className={classes.plainText}>which is difficult because</Typography>
        <div className={classes.fieldSet}>
            <TextField value={gap.fields.Challenge || ''} onChange={(e) => updateFields(clientId, { Challenge: e.target.value })} label="Enter challenge" variant="outlined" classes={{ root: classes.challengeField }} />
            <Button
                //disabled={!onDelete}
                className={classes.randomChallengeButton}
                variant={'outlined'}
                disabled={loading}
                //endIcon={<CachedIcon />}
                size={'small'}
                color="primary"
                onClick={() => randomize('Challenge')}
            >Random</Button>
        </div>
        <Button
            disabled={!onDelete}
            className={classes.deleteButton}
            startIcon={<DeleteIcon />}
            onClick={onDelete}
        >Delete Gap</Button>
    </div>
}

export default GapInput