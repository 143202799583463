import React, { useCallback, useState } from 'react'
import { Gap, Solution, LoadingState } from '../../../types'
import { makeStyles, Typography, TextField, Button, Box } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';
import { useSolutions } from '../../../hooks/solutionHooks';
import GapDisplay from '../../GapDisplay';
import RefreshIcon from '@material-ui/icons/Refresh';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0),
        }, 
    },
    gap: {
        display: 'inline'
    },
    prompt: {
        margin: theme.spacing(2, 0, 0)
    },
    newGapPrompt: {
        color: theme.palette.grey[500],
        lineHeight: '1.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    }
}))

type Props = {
    unsolvedGap: Gap
    solutions: LoadingState<Solution>[]
}

const SolutionGroup: React.FC<Props> = (props) => {
    const { unsolvedGap, solutions } = props
    const { addSolution, updateFields, replaceUnsolvedGap } = useSolutions()
    const [isLoadingNewGap, setLoadingNewGap] = useState(false);
    const classes = useStyles(props)

    const handleAdd = useCallback(() => {
        addSolution(unsolvedGap.id)
    }, [addSolution, unsolvedGap.id])

    const onLoadNewGap = useCallback(async () => {
        setLoadingNewGap(true)
        await replaceUnsolvedGap(unsolvedGap.id)
        setLoadingNewGap(false)
    }, [replaceUnsolvedGap, unsolvedGap.id, setLoadingNewGap])

    return <div className={classes.root}>
        {isLoadingNewGap ? <Skeleton variant="rect" width={'100%'} height={100} /> : <GapDisplay gap={unsolvedGap} />}
        <Box className={classes.newGapPrompt}>
            {
                isLoadingNewGap ? <span>Loading...</span> : <>
                    <span style={{ cursor: 'pointer' }} onClick={onLoadNewGap}>Skip this gap</span>
                    <RefreshIcon style={{ cursor: 'pointer' }} onClick={onLoadNewGap} />
                </>
            }
        </Box>

        <Typography className={classes.prompt}>Something that would help to close this gap is... </Typography>

        {solutions.map(solution => <TextField
            label={solution.isLoading ? 'Loading' : "A solution"}
            key={solution.clientId}
            disabled={solution.isLoading || isLoadingNewGap}
            fullWidth
            value={solution.data?.fields.Solution}
            onChange={e => updateFields(solution.clientId, unsolvedGap.id, { Solution: e.target.value })} // should add a max length and possibly validation. 200 chars?
        />)}

        <Button
            startIcon={<AddIcon />}
            onClick={handleAdd}
        >Add another idea {/*(or hit enter)*/}</Button>
    </div>
}

export default SolutionGroup