import React from 'react'
import { Stepper, Step, useTheme, makeStyles, Button, StepButton, MobileStepper, useMediaQuery, StepLabel, ButtonBase } from '@material-ui/core'
import { useHistory, useParams } from 'react-router'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { StepIconProps } from '@material-ui/core/StepIcon';
import clsx from 'clsx';
import { ProgressStep } from '../../types';

interface Props {
    step: ProgressStep
    paperHeight: number
}

/*function addBuffer(data:number) {
    return data + 10;
  }
const safePaperHeight = addBuffer(paperHeight)*/ // this isn't working, need to come back to it, but essentially things get really jittery on the margins when the window height and page height are close/equal

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~~ STYLES ~~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

const useStyles = makeStyles(theme => ({
    stepSpace: {
        padding: theme.spacing(1, 0, 2),
        marginTop: 1
    },
    buttonHolderTallPaper: { // fake sticky behavior
        backgroundColor: theme.palette.grey[100],
        boxShadow: '0px -1px 5px lightgrey',
        margin: theme.spacing(0, -10, 0),
        padding: theme.spacing(0, 0, 1, 10),
        zIndex: 1000,
        position: 'fixed',
        bottom: 0,
        maxWidth: (theme.breakpoints.values.lg - 40),
        width: 'calc(100% - 40px)', // ideally this would be - theme.spacing(2), but the calc doesn't work that way
    },
    buttonHolderShortPaper: {
        backgroundColor: theme.palette.grey[100],
        boxShadow: '0px -1px 5px lightgrey',
        width: '100%',
        position: 'absolute',
        bottom: 0,
        margin: theme.spacing(0, -10, 0),
        padding: theme.spacing(.25, 0, 1.25, 10),
    },
    shortPaperSpacer: {
        height: theme.spacing(2),
    },
    buttons: {
        '& >': {
            '&:first-child': {
                marginRight: theme.spacing(2)
            },
        },
        marginTop: theme.spacing(1),
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: theme.palette.grey[500],
        margin: theme.spacing(0, .25)
    },
    active: {
        backgroundColor: theme.palette.primary.main
    },
    stepButton: {
        textTransform: 'capitalize',
    },
    startButton: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        fontFamily: 'Roboto Mono, monospace',
        textTransform: 'uppercase',
        borderRadius: '1.25rem',
    },
    buttonSpacer: {
        width: '75px',
    },
    pillButton: { // I couldn't get this to work in buttons, maybe because of hierarchy with the Material UI
        fontFamily: 'Roboto Mono, monospace',
        textTransform: 'uppercase',
        borderRadius: '1.25rem',
    }
}))

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~~ Step Definition ~~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

type StepDefinition = { step: ProgressStep, label: string, substeps?: string[] }

type SubstepIconProps = StepIconProps & { step: ProgressStep, substeps: string[], currentSubstep: string };
const SubstepIcons: React.FC<SubstepIconProps> = (props) => {
    const classes = useStyles()
    const history = useHistory()
    
    return <>
        {props.substeps.map(substep => <ButtonBase
            key={substep}
            onClick={() => history.push(`/game/${props.step}/${substep}`)}
        >
            <div className={clsx(classes.circle, { [classes.active]: substep === props.currentSubstep })} />
        </ButtonBase>
        )}
    </>
}

const steps: StepDefinition[] = [
    { step: 'intro', label: 'Intro' },
    { step: 'spotting-gaps', label: 'Spotting Gaps' }, // substeps: ['identification', 'prioritization']  if you enable the prioritization screen
    { step: 'solutioning', label: 'Solutioning', substeps: ['1', '2', '3'] },
    { step: 'scoring', label: 'Scoring', substeps: ['1', '2', '3'] },
    { step: 'survey', label: 'Survey' },
    { step: 'finish', label: 'Finish' }, // Maybe we could lose this as a dot and treat it differently?
]

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~~ Button Logic ~~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

const ProgressTracker: React.FC<Props> = ({ children, step, paperHeight }) => {
    const classes = useStyles()
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const tallPaper = useMediaQuery('(max-height:'+(paperHeight+theme.spacing(7))+'px)'); // additional space to mitigate jittery self-referential math
    const history = useHistory()
    const { substep } = useParams();
    
    /*
    console.log("the paper is taller than the screen: "+tallPaper)
    console.log(paperHeight)
    */

    const activeStepIdx = steps.findIndex(s => s.step === step)
    const activeStep = steps[activeStepIdx]

    const { substeps = [] } = activeStep;
    const hasSubsteps = substeps.length > 0
    const substepIdx = (hasSubsteps && substep) ? substeps.indexOf(substep) : -1

    // go to the first substep, if there is one
    React.useEffect(() => {
        if (hasSubsteps && !substep) {
            history.replace(`/game/${activeStep.step}/${substeps[0]}`)
        }
    }, [substep, activeStep, history, substeps, hasSubsteps])

    const onBack = React.useCallback(() => {
        if (hasSubsteps && substepIdx > 0) {
            history.push(`/game/${activeStep.step}/${substeps[substepIdx - 1]}`)
        }
        else {
            const prevStep = steps[activeStepIdx - 1]
            if (prevStep.substeps) {
                history.push(`/game/${prevStep.step}/${prevStep.substeps[prevStep.substeps.length - 1]}`)
            }
            else {
                history.push(`/game/${prevStep.step}`)
            }
        }
    }, [activeStepIdx, history, activeStep, hasSubsteps, substepIdx, substeps])

    const onNext = React.useCallback(() => {
        if (activeStepIdx + 1 === steps.length) {
            // finish
            return;
        }
        if (hasSubsteps && substepIdx < (substeps.length - 1)) {
            history.push(`/game/${activeStep.step}/${substeps[substepIdx + 1]}`)
            window.scrollTo(0,0)
        }
        else {
            const nextStep = steps[activeStepIdx + 1]
            history.push(`/game/${nextStep.step}`)
            window.scrollTo(0,0)
        }

    }, [activeStepIdx, history, activeStep, hasSubsteps, substepIdx, substeps])

    const nextBtnLabel = activeStepIdx + 2 === steps.length ? 'Finish' : 'Next'
    const isFirstPage = activeStepIdx === 0
    const isLastPage = activeStepIdx + 1 === steps.length

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~~ Regular Top Stepper ~~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

    return <>
        {!mobile && <Stepper
            className={classes.stepSpace}
            activeStep={activeStepIdx}
            nonLinear
        >{steps.map(({ label, step, substeps }, idx) => <Step key={step}>
            {substeps !== undefined && activeStepIdx === idx ? <StepLabel
                StepIconComponent={SubstepIcons}
                StepIconProps={{ step, substeps, currentSubstep: substep } as any}
            >
                <Button className={classes.stepButton} onClick={() => history.push(`/game/${step}`)}>{label}</Button>
            </StepLabel> : <StepButton
                completed={false}
                style={{ padding: '0 auto' }}
                onClick={() => history.push(`/game/${step}`)}
            >   
                    {label}
                </StepButton>}
        </Step>)}</Stepper>}

        {children}

{/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~~ Tall Paper Navigation ~~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}

        {!mobile && tallPaper && <div className={classes.buttonHolderTallPaper}>
            <div className={classes.buttons}>

            {!isFirstPage &&<Button variant="contained" className={classes.pillButton} color="secondary" onClick={onBack} disabled={activeStepIdx === 0}>Back</Button>}

            {isFirstPage && <Button variant="contained" className={classes.startButton} color="primary" onClick={onNext}>Get Started!</Button>} {/* Custom starting text */}

            {!isFirstPage && !isLastPage && <Button variant="contained" className={classes.pillButton} color="primary" onClick={onNext}>{nextBtnLabel}</Button>}

            {isLastPage && <Button variant="contained" className={classes.pillButton} color="primary" disabled={true}>You did it!</Button>} {/* Custom ending text */}
            
            </div>
        </div>}

{/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~~ Short Paper Navigation ~~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}

        {!mobile && !tallPaper && <>
        <div className={classes.shortPaperSpacer}></div>
        <div className={classes.buttonHolderShortPaper}>
            <div className={classes.buttons}>

            {!isFirstPage &&<Button variant="contained" className={classes.pillButton} color="secondary" onClick={onBack} disabled={activeStepIdx === 0}>Back</Button>}

            {isFirstPage && <Button variant="contained" 
                className={classes.startButton} color="primary" onClick={onNext}>Get Started</Button>} {/* Custom starting text */}

            {!isFirstPage && !isLastPage && <Button variant="contained" className={classes.pillButton} color="primary" onClick={onNext}>{nextBtnLabel}</Button>}

            {isLastPage && <Button variant="contained" className={classes.pillButton} color="primary" disabled={true}>You did it!</Button>} {/* Custom ending text */}

            </div>
        </div>
        </>}

{/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~~ Mobile Navigation ~~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
        {mobile && <MobileStepper
            steps={steps.length}
            position="bottom"
            variant="dots"
            elevation={5}
            activeStep={activeStepIdx}
            nextButton={(!isLastPage && !isFirstPage)  ?
                <Button size="small" onClick={onNext}>
                    {nextBtnLabel}
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </Button> :
                (isFirstPage  ?
                    <Button size="small" onClick={onNext}>
                        Start! {/* Custom starting text */}
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </Button> :
                <Button size="small" disabled={true}>
                    Done! {/* Custom ending text */}
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </Button>)
            }
            backButton={
                <Button size="small" onClick={onBack} disabled={activeStepIdx === 0}>
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                    Back
                </Button>
            }
        />}
    </>
}

export default ProgressTracker