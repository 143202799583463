import React, { useEffect } from 'react'
import { makeStyles, Paper } from '@material-ui/core'
import BasePage from '../BasePage'
import { ProgressStep } from '../../types'
import { useParams } from 'react-router-dom'
import ProgressTracker from './ProgressTracker'
import WelcomeHeader from './WelcomeHeader'
const useStyles = makeStyles(theme => ({
    paper: {
        transitionProperty: 'height',
        transitionDuration: '.16s',
        transitionTimingFunction: 'ease-out',
        overflow: 'hidden',
        height: 0,
        margin: '0 auto', // I guess this uses flexbox, so maybe I should use something else?
        maxWidth: theme.breakpoints.values.lg, // this feels wide to me, but sm seems narrow, we should aim for ~60-75 characters across, and this is ~110
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(6),
        }, // creates space for mobile navigation
    },
    innerPaper: {
        display: 'block',
        padding: theme.spacing(4, 10, 8),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1, 2, 2)
        },
        position: 'relative',
        overflowX: 'hidden',
        width: '100%'
    },
}))
const Survey: React.FC = (props) => {
    const classes = useStyles(props)
    const { step } = useParams();
    const [paperHeight, setPaperHeight] = React.useState(0);
    const innerPaper = React.useRef<HTMLDivElement>(null);
    const welcomeHeader =  <WelcomeHeader />
    // a hacky way to get the paper resize animation // clunky with hacky sticky nav
    useEffect(() => {
        const timer = setInterval(() => {
            if (innerPaper.current && innerPaper.current.offsetHeight !== paperHeight) {
                setPaperHeight(innerPaper.current.offsetHeight)
            }
        }, 16)
        return () => {
            clearInterval(timer)
        }
    }, [paperHeight])
    return <BasePage title="Closing Gaps Ideation">
        <Paper square={true}>
            {step === 'intro' && welcomeHeader}
        </Paper>
        <Paper square={true} className={classes.paper} style={{ height: paperHeight }}>
            <div ref={innerPaper} className={classes.innerPaper}>
                <ProgressTracker paperHeight={paperHeight as number} step={step as ProgressStep}>
                    {props.children}
                </ProgressTracker>
                {/*{step==='intro' && !mobile && headSpace}*/} {/* Hacking around an annoying issue where the header means that the paper isn't the right length... May need to add header to computed paper length*/}
            </div>
        </Paper>
    </BasePage>
}
export default Survey;