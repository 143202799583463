import React, { createContext, useState, useEffect } from "react"
import fetchJson from "../utils/data";
import { ClientSolution, ClientGap, ClientSolutionScore } from "./SurveyContext";

// generic store for global values

type GlobalDataContextType = {
    counts?: {
        gaps: number,
        solutions: number,
        scores: number
    },
    heatmap: any,
    words?: any[]
    setHiddenSurveyItems?: any,
    hiddenSurveyItems: { gaps: ClientGap[], solutions: ClientSolution[], scores: ClientSolutionScore[] }
}

export const GlobalDataContext = createContext<GlobalDataContextType>({ counts: undefined, heatmap: undefined, words: undefined, hiddenSurveyItems: { gaps: [], solutions: [], scores: [] } })

export const useGlobalData = () => {
    const [heatmap, setHeatmap] = useState<number[][] | undefined>(undefined);
    const [counts, setCounts] = useState<any>(undefined)
    const [words, setWords] = useState()
    const [hiddenSurveyItems, setHiddenSurveyItems] = useState({ gaps: [], solutions: [], scores: []});
    useEffect(() => {
        async function getCounts() {
            const response = await fetch('/api/stats/counts')
            const counts = await response.json()
            setCounts(counts)
        }

        getCounts()
    }, [])

    useEffect(() => {
        async function getHeatmap() {
            const response = await fetchJson<number[][]>('/api/solutions/heatmap', 'GET')
            setHeatmap(response);
        }
        getHeatmap();
    }, [])

	useEffect(() => {
		async function run() {
			const response = await fetch('/api/getWordcloudWords')
			const words = await response.json();
			setWords(words)
		}
		run()
	}, [])

    return { heatmap, counts, words, hiddenSurveyItems, setHiddenSurveyItems, ready: heatmap && counts && words }
}

export const GlobalDataContextProvider: React.FC<{value: GlobalDataContextType}> = ({children, value}) => {
    return <GlobalDataContext.Provider value={value}>
        {children}
    </GlobalDataContext.Provider>
}