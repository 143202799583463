import { useSurvey } from "../context/SurveyContext";
import { useEffect, useCallback } from "react";
import fetchJson from "../utils/data";
import { Gap, Solution } from "../types";
import uuidv4 from 'uuid/v4'
import { debounce } from "debounce";

const updateSolution = (playerId: string, gapId: string, solution: Partial<Solution>) =>
    fetchJson(`/api/gaps/${gapId}/solutions?playerId=${playerId}`, 'PATCH', {
        ...solution
    })

const updateSolutionDebounced = debounce(updateSolution, 200)

export function useSolutions(unsolvedGaps: number = 3) {
    const survey = useSurvey();
    const dispatch = survey.dispatch

    const addSolution = useCallback(async (gapId: string) => {
        const clientId = uuidv4()

        dispatch({
            type: 'ADD_SOLUTION',
            payload: {
                clientId,
                clientGapId: gapId,
                isLoading: true,
                error: null,
                data: null
            }
        })

        const solution = await fetchJson<Solution>(`/api/gaps/${gapId}/solutions?playerId=${survey.player.id}`, 'POST', {
            fields: {
                Solution: ''
            }
        } as Partial<Solution>)

        dispatch({
            type: 'UPDATE',
            payload: {
                clientId,
                clientGapId: gapId,
                isLoading: false,
                error: null,
                data: solution
            }
        })
    }, [survey.player.id, dispatch])

    const updateFields = useCallback(async (clientId: string, gapId: string, fields: Partial<Solution['fields']>, debounce = true) => {
        const existingSolution = survey.solutions.find(solution => solution.clientId === clientId);
        if (!existingSolution || existingSolution.isLoading || !existingSolution.data) {
            throw new Error("Solution doesn't exist????")
        }

        const data = {
            ...existingSolution.data,
            fields: {
                ...existingSolution.data.fields,
                ...fields
            }
        }

        dispatch({
            type: 'UPDATE',
            payload: {
                ...existingSolution,
                data
            }
        })

        await (debounce ? updateSolutionDebounced : updateSolution)(survey.player.id, gapId, {
            ...existingSolution.data,
            ...data
        })
    }, [dispatch, survey.player.id, survey.solutions])

    const deleteSolution = useCallback(async (clientId: string) => {
        const solutionToDelete = survey.solutions.find(solution => solution.clientId === clientId)
        if (!solutionToDelete) {
            return
        }

        if (solutionToDelete.data) {
            dispatch({
                type: 'UPDATE',
                payload: {
                    clientId,
                    isLoading: true,
                    error: null,
                    data: null
                }
            })

            await fetchJson<Gap>(`/api/solutions/${solutionToDelete.data.id}?playerId=${survey.player.id}`, 'DELETE', {})

            dispatch({
                type: 'DELETE',
                clientId
            })
        }
    }, [dispatch, survey.player.id, survey.solutions])

    const replaceUnsolvedGap = useCallback(async (id: string) => {
        // find the gap that the user wants to skip
        if (!survey.unsolvedGaps || survey.unsolvedGaps.isLoading || survey.unsolvedGaps.error) {
            // TODO: handle unexpected edge case
            return;
        }

        const gapToReplace = survey.unsolvedGaps.data.find(gap => gap.id === id)
        if (!gapToReplace) {
            // TODO: handle unexpected edge case
            return;
        }

        await Promise.all(survey.solutions.filter(solution => solution.clientGapId === id).map((solution) => {
            return updateFields(solution.clientId, solution.clientGapId, {
                GapSkippedByPlayer: true
            }, false)
        }))

        // get a new gap from the api
        const currentGapIds = survey.unsolvedGaps.data.map(gap => gap.id);
        // TODO: consider only excluding *solutioning* gaps
        const gaps = await fetchJson<Gap[]>(`/api/randomWeightedGap?amount=${1}&exceptFor=${currentGapIds.join(',')}`, 'GET')
        const newGap = gaps[0]
        if (!survey.solutions.find(solution => solution.clientGapId === newGap.id)) {
            addSolution(newGap.id)
        }

        dispatch({
            type: 'SET_UNSOLVED_GAPS',
            payload: {
                clientId: survey.unsolvedGaps.clientId,
                isLoading: false as false,
                error: null,
                data: survey.unsolvedGaps.data.map(gap => gap.id === gapToReplace.id ? newGap : gap)
            }
        })
    }, [survey.unsolvedGaps, survey.solutions, addSolution, dispatch, updateFields])

    useEffect(() => {
        async function fetchUnsolvedGaps() {
            const clientId = uuidv4()
            dispatch({
                type: 'SET_UNSOLVED_GAPS',
                payload: {
                    clientId,
                    isLoading: true as true,
                    error: null,
                    data: null
                }
            })

            const gaps = await fetchJson<Gap[]>(`/api/randomWeightedGap?amount=${unsolvedGaps}`, 'GET')
            gaps.forEach(gap => {
                addSolution(gap.id)
            })

            dispatch({
                type: 'SET_UNSOLVED_GAPS',
                payload: {
                    clientId,
                    isLoading: false as false,
                    error: null,
                    data: gaps
                }
            })
        }
        if (!survey.unsolvedGaps) {
            fetchUnsolvedGaps();
        }
    }, [dispatch, unsolvedGaps, survey.unsolvedGaps, addSolution])

    return {
        solutions: survey.solutions,
        unsolvedGaps: survey.unsolvedGaps,
        addSolution, deleteSolution, updateFields,
        replaceUnsolvedGap
    }
}