import Snap from 'snapsvg-cjs'

export function initialize() {
    //Script Varriables
    var svgObject; //svgHeader SVG object
    var svgHeader; //svgHeader element
    var svgEntities = []; //Array of entities
    var forcePointX = 0; //X point of force source (Currently tied to mouse)
    var forcePointY = 0; //Y point...
    var resolutionFactor = 1.0; //svg vs mouse coordinates conversion factor
    var mouseOffsetLeft = 0; //Distance from left edge of viewport to SVG to translate mouse
    var mouseOffsetTop = 0;
    var lastTimestamp = Date.now(); //timestamp  between frames to determine time difference
    var inView = true;
    var haltFrame = 0;
    var tensionForce = 65; //force of mouse when close
    var maxDisplacement = 40;
    var effectRadius = 400; //how far away do we beghin caring about the mouse
    var friction = 0.97; // 1.0 = no friction, 0.0 = 100% friction
    var isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') == -1 &&
        navigator.userAgent.indexOf('FxiOS') == -1;


    function draw() {
        requestAnimationFrame(draw); //wait until we're ready to draw again before bothering with all this:
        if (svgHeader) {
            updateLoop();
        }
        else {
            loadEntities();
            return;
        }
    }

    function vectorMagnitude(x, y) {
        return Math.sqrt(x * x + y * y);
    }

    function normalize2Dvec(x, y) {
        var magnitude = vectorMagnitude(x, y);
        if (magnitude === 0) {
            return {
                x: 0,
                y: 0
            }
        }; // don't stumble into a divide by zero
        return {
            x: x / magnitude,
            y: y / magnitude
        };
    }

    function getSubDocument(embedding_element) // gets the actual svg data in whatever way works
    {
        if (embedding_element.contentDocument) {
            return embedding_element.contentDocument;
        } else {
            var subdoc = null;
            try {
                subdoc = embedding_element.getSVGDocument();
            } catch (e) { }
            return subdoc;
        }
    }


    function updateLoop() {
        var dt = (Date.now() - lastTimestamp) / 1000.0;
        if (dt > 1 / 10.0) {
            dt = 1 / 10.0;
        }
        haltFrame = haltFrame - 1;
        if (haltFrame < -1) { haltFrame = -1; }
        if (inView && haltFrame < 0) {
            if (svgHeader) {


                //svgHeader.style.visibility = false; //HACK to halt reflows when changing all this stuff 
                for (var counter = 0; counter < svgEntities.length; counter++) {

                    var entity = svgEntities[counter]; //Get Entity Object
                    if (entity) {
                        var vectorFromMouseX = forcePointX - entity.originX;
                        var vectorFromMouseY = forcePointY - entity.originY;

                        var normalizedVectorFromMouse = normalize2Dvec(vectorFromMouseX, vectorFromMouseY);
                        var distanceToMouse = vectorMagnitude(vectorFromMouseX, vectorFromMouseY);

                        var mouseForcePower = 0; //how much should we effect this object with the force
                        if (distanceToMouse < effectRadius) {
                            mouseForcePower = (effectRadius - distanceToMouse) / effectRadius; //linear
                            mouseForcePower = mouseForcePower * mouseForcePower; //geometric
                        }

                        //where the entity should move to given the force
                        var targetX = entity.originX + normalizedVectorFromMouse.x * maxDisplacement * mouseForcePower;
                        var targetY = entity.originY + normalizedVectorFromMouse.y * maxDisplacement * mouseForcePower;

                        //vector from where we're at to where we shuld be
                        var vectorTowardsTargetX = entity.positionX - targetX;
                        var vectorTowardsTargetY = entity.positionY - targetY;

                        //less force when we're closer, more when we're further
                        //var distanceFromTarget = vectorMagnitude(vectorTowardsTargetX, vectorTowardsTargetY);

                        var normalizedVectorTowardsTarget = normalize2Dvec(vectorTowardsTargetX, vectorTowardsTargetY);

                        var forceX = normalizedVectorTowardsTarget.x * tensionForce;
                        var forceY = normalizedVectorTowardsTarget.y * tensionForce;


                        //Apply Forces to velocity
                        entity.velocityX = entity.velocityX + forceX * dt; //add Force * time elapsed since last frame
                        entity.velocityY = entity.velocityY + forceY * dt;

                        //Apply Friction
                        entity.velocityX = entity.velocityX * friction;
                        entity.velocityY = entity.velocityY * friction;


                        //Move according to velocity
                        entity.positionX = entity.positionX - entity.velocityX * dt;
                        entity.positionY = entity.positionY - entity.velocityY * dt;

                        //Rotation

                        //Random Rotation
                        //Math.sin(Date.now()*entity.angleOffset)*6.0
                        var magicRotation = 0;

                        var translationX = entity.originX - entity.positionX;
                        var translationY = entity.originY - entity.positionY;

                        if (Number.isNaN(translationX) || Number.isNaN(translationY)) {
                            loadEntities();
                            return;
                        }

                        if (!isSafari) { //svg rotations are bugged in safari for multiple transformation origins
                            magicRotation = (Math.sin(lastTimestamp * entity.angleOffset / 200) * 6.0);
                        }

                        //entity.element.setAttribute("transform-origin", entity.originX + " " + entity.originY);
                        //Set transform string
                        var transformString = 'translate(' +
                            (translationX).toFixed(1) +
                            ',' +
                            (translationY).toFixed(1) +
                            ') rotate(' +
                            magicRotation.toFixed(1) +
                            ')';
                        entity.element.setAttribute("transform", transformString);

                    }


                }


                //svgHeader.style.visibility = true;

            }
        } else {
            //when header not in view
        }

        lastTimestamp = Date.now();
    }

    function resizeDuty() {
        console.log("resize duty")
        svgHeader = document.getElementById('svgHeader');
        if (svgHeader) {
            haltFrame = 10;
            checkInView();
            var boundingRect = svgHeader.getBoundingClientRect();
            if (svgEntities.length) {
                resolutionFactor = svgEntities[0].element.viewportElement.clientWidth / svgEntities[0].element.viewportElement.viewBox.baseVal.width;
            }
            mouseOffsetLeft = boundingRect.left;
            mouseOffsetTop = boundingRect.top;
            //forcePointX = svgEntities[0].element.viewportElement.viewBox.baseVal.width / 2.0;
            //forcePointY = svgEntities[0].element.viewportElement.viewBox.baseVal.height;
            //finding origins again
            for (var counter = 0; counter < svgEntities.length; counter++) {
                var boundingBox = svgEntities[counter].svg.getBBox();
                svgEntities[counter].originX = boundingBox.cx;
                svgEntities[counter].originY = boundingBox.cy;
                svgEntities[counter].positionX = boundingBox.cx;
                svgEntities[counter].positionY = boundingBox.cy;
                svgEntities[counter].element.setAttribute("transform-origin", svgEntities[counter].originX + " " + svgEntities[counter].originY);

            }
        } else { }
    }

    function checkInView() {
        console.log("check in view")
        if (svgHeader && !(window.getComputedStyle(svgHeader).visibility === "hidden") && svgHeader.getBoundingClientRect().bottom > 0) {
            inView = true;
        } else {
            inView = false;
        }
    }

    function loadEntities() {
        console.log("load entities?")

        svgHeader = document.getElementById('svgHeader');

        if (!svgHeader) { //pump for svgheader existence
            return;
        }
        svgHeader.style.opacity = 1.0;
        svgObject = getSubDocument(svgHeader);

        if (!svgObject) { //hack to truly wait for svg to be available
            return;
        }

        console.log(svgObject)

        var entityNumber = 1;

        while (true) {
            console.log("in while loop")
            var entityElement = svgObject.getElementById("Entity" + entityNumber);

            if (entityElement) {
                var svgElement = Snap(entityElement);
                entityNumber = entityNumber + 1;
                svgEntities.push({
                    element: entityElement,
                    svg: svgElement,
                    originX: svgElement.getBBox().cx,
                    originY: svgElement.getBBox().cy,
                    velocityX: 0, //20 - Math.random()*40,
                    velocityY: 0, //20 - Math.random()*40,
                    positionX: svgElement.getBBox().cx,
                    positionY: svgElement.getBBox().cy,
                    angleOffset: Math.random() / 2.0
                });
            } else {
                break;
            }
        }

        window.addEventListener("resize", resizeDuty);
        window.addEventListener("scroll", checkInView);

        svgObject.addEventListener('mousemove', e => {
            e.preventDefault();
            forcePointX = (e.clientX) / resolutionFactor;
            forcePointY = (e.clientY) / resolutionFactor;
        });

        document.getElementById('root').addEventListener('mousemove', e => {
            forcePointX = (e.clientX - mouseOffsetLeft) / resolutionFactor;
            forcePointY = (e.clientY - mouseOffsetTop) / resolutionFactor;
        });


        //BEGIN
        resizeDuty();
        lastTimestamp = Date.now();
        draw();
    }
    loadEntities();
}