import React, { useContext } from 'react';
import { Typography, makeStyles, Button, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Box, useTheme, useMediaQuery } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useGaps } from '../../../hooks';
import GapLoader from './GapLoader';
import { useGapScores } from '../../../hooks/gapScoreHooks';
import GapScore from './GapScoring';
import { GlobalDataContext } from '../../../context/GlobalDataContext';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
    gap: {
        background: theme.palette.grey[100],
        padding: theme.spacing(3),
        margin: theme.spacing(2, 0),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1, 1.5),
        },
    },
    gapBox: {
        background: theme.palette.grey[100],
        padding: theme.spacing(1, 3),
        margin: theme.spacing(1, 0, 3),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1, 0, 2),
            padding: theme.spacing(1, 1.5),
        },
    },
    operationalize: {
        display: 'inline',
        fontWeight: 500,
    },
    need: {
        display: 'inline',
        fontWeight: 500,
        color: theme.palette.secondary.main
    },
    because: {
        display: 'inline',
    },
    challenge: {
        display: 'inline',
        fontWeight: 500,
        color: theme.palette.primary.main
    },
    textBlock: {
        margin: theme.spacing(2, 0, 3),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1, 0, 2),
        },
    },
    emBlock: {
        margin: theme.spacing(2, 0, 0),
        fontWeight: 600,
    },
    moreInfo: {
        margin: theme.spacing(3,0),
    },
    summary: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontWeight: 600,
    },
    howToScore: {
        margin: theme.spacing(1.5,0,0),
    },
}))


const SpotAndScore: React.FC = () => {
    const {
        gaps,
        addGap
    } = useGaps()

    const numOfGapsToScore = 5
    const { gapScores } = useGapScores(numOfGapsToScore);
    const { counts } = useContext(GlobalDataContext);
    const classes = useStyles();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    return <div>
        <Typography className={classes.emBlock}>
            Thank you for participating in Closing Gaps!
        </Typography>
        <Typography className={classes.textBlock}>
            The first step in Closing Gaps is to identify where gaps exist. The community has identified <strong>{counts?.gaps}</strong> gaps between responsible AI principles and day-to-day practice so far.
        </Typography>
        <Typography className={classes.textBlock}>
            You will be presented with {numOfGapsToScore} gaps. For each gap, think about your own personal and professional experience to assign a rating from 1 (low) to 5 (high) for how relatable and important each gap is for you.
        </Typography>
        <ExpansionPanel className={classes.moreInfo}>
            <ExpansionPanelSummary className={classes.summary} expandIcon={<ExpandMoreIcon className={classes.summary} />}>
                <Typography variant="subtitle2">
                    How to score {!mobile && <span style={{fontSize: '.75rem', padding: '0 1rem'}}>(Click to view)</span>} {mobile && <span style={{fontSize: '.75rem', padding: '0 0'}}>(Tap to view)</span>}
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Box>
                    <Typography className={classes.howToScore}>
                        A gap at level 1 relatable would be something you’ve never come across before in your work. At level 5 relatable, the gap describes challenges and needs that you have personally faced in operationalizing responsible AI. In between might be gaps you’ve heard of but never encountered.
                    </Typography>
                    <Typography className={classes.howToScore}>
                        A gap at level 1 importance would be something that is not particularly urgent or instrumental in pursuit of responsible AI. At level 5 importance, the gap describes crucial needs and challenges faced by people pursuing responsible AI.
                    </Typography>
                </Box>
            </ExpansionPanelDetails>
        </ExpansionPanel>

        <Typography>
            Score each gap on how relatable and important it is to you:
        </Typography>
        <Box marginTop={2}>
            {gapScores.map(gapScore => <GapScore gapScore={gapScore} key={gapScore.clientId} />)}
        </Box>
        <ExpansionPanel className={classes.moreInfo}>
            <ExpansionPanelSummary className={classes.summary} expandIcon={<ExpandMoreIcon className={classes.summary} />}>
                <Typography variant="subtitle2">
                    What are your gaps?
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Box>
                    <Typography className={classes.textBlock}>
                        What do you or does your organization <Typography className={classes.need}>NEED</Typography> to operationalize responsible AI (i.e. ensure that AI use and development are pursued responsibly)? What <Typography className={classes.challenge}>CHALLENGES</Typography> stand in the way of addressing those needs?
                    </Typography>
                    <Typography>
                        Write your ideas below using the following format:
                    </Typography>
                    <div className={classes.gapBox}>
                        <Typography className={classes.operationalize}> To operationalize responsible AI,</Typography>
                        <Typography className={classes.need}>&#32;&#40;who needs what?&#41;</Typography>
                        <Typography className={classes.because}>, which is difficult because</Typography>
                        <Typography className={classes.challenge}>&#32;&#40;why is this still an issue?&#41;</Typography>
                    </div>
                    <Typography className={classes.textBlock}>
                        This is an expansionary part of the ideation. We want to get all the ideas out there including (and perhaps <em>especially) the seemingly mundane and ridiculous ones.</em> If you're feeling stumped, use the 'RANDOM' buttons to display ideas from other players for inspiration.
                    </Typography>
                    <Typography className={classes.emBlock}>
                        Use the blanks below to share as many ideas as possible. Don’t hold back!
                    </Typography>
                    {gaps.map((gap, index) => <div key={gap.clientId} className={classes.gap}>
                        <GapLoader className={classes.gap} key={index} gap={gap} />
                    </div>)}
                    <Button
                        startIcon={<AddIcon />}
                        onClick={addGap}
                    >Add another Gap</Button>

                    <Typography className={classes.textBlock}>
                        Remember: avoid using any names of teams, companies, people, or products that may be identifying.
                    </Typography>
                    <Typography className={classes.emBlock}>
                        Shared all your ideas? Feel free to move on to the next activity at any point! You’ll have an opportunity to identify more gaps later if you’re feeling up to it.
                    </Typography>
                
                </Box>
            </ExpansionPanelDetails>
        </ExpansionPanel>


    </div>
}

export default SpotAndScore;