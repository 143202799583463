import React from 'react'
import { Gap, GapWithSolutions } from '../types'
import { makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'

type Props = {
    gap: Gap | GapWithSolutions
}

const useStyles = makeStyles(theme => ({
    gapBox: {
        background: theme.palette.grey[100],
        padding: theme.spacing(1,3),
        margin: theme.spacing(0),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1,1.5),
        },
    },
    need: {
        display: 'inline',
        fontWeight: 500,
        color: theme.palette.secondary.main
    },
    because: {
        display: 'inline',
    },
    challenge: {
        display: 'inline',
        fontWeight: 500,
        color: theme.palette.primary.main
    }
}))

const GapDisplay: React.FC<Props> = (props) => {
    const { gap } = props;
    const classes = useStyles(props)
    return <div className={classes.gapBox}>
        <Typography className={classes.need}>{gap.fields.Need}</Typography> 
        <Typography className={clsx(classes.because)}>, which is difficult because&#32;</Typography> 
        <Typography className={classes.challenge}>{gap.fields.Challenge}</Typography>
    </div>
}

export default GapDisplay