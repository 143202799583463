import React, { useCallback } from 'react'
import { Dialog, DialogContent, makeStyles, Paper, DialogActions, Button, Typography } from '@material-ui/core'
import { useSurvey, SurveyProvider, SurveyContextState } from '../../../context/SurveyContext'
import AddIcon from '@material-ui/icons/Add';
import { useGaps } from '../../../hooks'
import GapLoader from '../SpottingGaps/GapLoader'
import { useSolutions } from '../../../hooks/solutionHooks';
import SolutionGroup from '../Solutioning/SolutionGroup';
import { useSolutionsScores } from '../../../hooks/solutionScoreHooks';
import ScoreInput from '../Scoring/ScoreInput';

export type Activity = 'gaps' | 'solutions' | 'scoring'
type Props = {
    activity: Activity
    onClose: (innerSurvey: SurveyContextState) => void
}

const useStyles = makeStyles(theme => ({
    dialog: {
        backgroundColor:'#fafafa',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0),
            paddingTop: 0,
        },
    },
    paper: {
        transitionProperty: 'height',
        transitionDuration: '.16s',
        transitionTimingFunction: 'ease-out',
        overflow: 'scroll',
        height: 0,
        margin: '0 auto',
        maxWidth: theme.breakpoints.values.lg,
    },
    innerPaper: {
        display: 'block',
        padding: theme.spacing(4, 10, 8),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1, 2, 2)
        },
        position: 'relative',
        overflowX: 'hidden',
        width: '100%'
    },
    gap: {
        background: theme.palette.grey[100],
        padding: theme.spacing(3),
        margin: theme.spacing(2, 0),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1.5),
        }, 
    },
    gapPrompt: {
        margin: theme.spacing(2, 0, 1),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1, 0, 1),
        }, 
    },
    pillButton: {
        fontFamily: 'Roboto Mono, monospace',
        textTransform: 'uppercase',
        borderRadius: '1.25rem',
    },
}))

        

const MoreGaps: React.FC = () => {
    const {
        gaps,
        addGap
    } = useGaps();
    const classes = useStyles();

    return <div>
        {
            gaps.map((gap, index) => <div key={gap.clientId} className={classes.gap}>
                <GapLoader key={index} gap={gap} />
            </div>)
        }
        < Button
            startIcon={< AddIcon />}
            onClick={addGap}
        > Add another Gap</Button >
    </div>
}

const MoreSolutions: React.FC = () => {
    const { unsolvedGaps, solutions } = useSolutions(1);
    const classes = useStyles();

    if (!unsolvedGaps || unsolvedGaps.isLoading) {
        return <div>Loading...</div> // TODO: better loading UI
    }

    return <div>
        <Typography className={classes.gapPrompt}>
            The gap is...
                </Typography>
        {unsolvedGaps.isLoading === false && unsolvedGaps.error === null && unsolvedGaps.data.map(unsolvedGap => <SolutionGroup unsolvedGap={unsolvedGap} solutions={solutions.filter(sol => sol.clientGapId === unsolvedGap.id)} />)}
    </div >
}

const MoreScoring: React.FC = () => {
    const {
        gapsForSolutionScoring
    } = useSolutionsScores(1);
    const classes = useStyles();

    if (!gapsForSolutionScoring || gapsForSolutionScoring.isLoading) {
        return <div>Loading...</div> // TODO: better loading UI
    }

    if (gapsForSolutionScoring.error) {
        return <div>Error</div>
    }

    return <div>
                <Typography className={classes.gapPrompt}>
                    For the gap...
                </Typography>
                {gapsForSolutionScoring.data.map(gapForScoring => <ScoreInput gapForScoring={gapForScoring} />)}
            </div>
}

const InnerDialog: React.FC<Props> = ({ activity, onClose }) => {
    const classes = useStyles();
    const innerPaper = React.useRef<HTMLDivElement>(null);
    const survey = useSurvey();

    const close = useCallback(() => {
        onClose(survey as SurveyContextState);
    }, [onClose, survey]);

    const activityComponent = activity === 'gaps' ?
        <MoreGaps /> :
        activity === 'solutions' ?
            <MoreSolutions /> :
            activity === 'scoring' ?
                <MoreScoring /> :
                <></>

    return <Dialog
        fullScreen={true}
        open={true}
        onClose={close}
    >
        <DialogContent
            className={classes.dialog}>
            <Paper className={classes.paper} style={{ minHeight: '100%' }}>
                <div ref={innerPaper} className={classes.innerPaper}>
                    {activityComponent}
                </div>
            </Paper>
        </DialogContent>
        <DialogActions>
            <Button onClick={close} variant="contained" className={classes.pillButton} color="primary" autoFocus>
                Finish
            </Button>
        </DialogActions>
    </Dialog>
}

const KeepPlaying: React.FC<Props> = (props) => {
    const { player } = useSurvey();

    return <SurveyProvider player={player}>
        <InnerDialog {...props} />
    </SurveyProvider>
}

export default KeepPlaying