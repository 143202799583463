import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { useRouteMatch, Route, Switch } from 'react-router-dom'
import Instructions from './Instructions'
import { useSolutions } from '../../../hooks/solutionHooks'
import SolutionGroup from './SolutionGroup'

const useStyles = makeStyles(theme => ({
    textBlock: {
        margin: theme.spacing(2, 0, 1),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2, 0, 1),
        },
    },
    gapPrompt: {
        margin: theme.spacing(2, 0, 1),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1, 0, 1),
        }, 
    },
}))

const Prompt: React.FC = () => {
    const match = useRouteMatch<{number: string}>()
    const num = Number(match.params.number) - 1;
    const { unsolvedGaps, solutions } = useSolutions();
    const classes = useStyles();

    if (!unsolvedGaps || unsolvedGaps.isLoading) {
        return <div>Loading...</div> // TODO: better loading UI
    }

    return <>
        <Typography className={classes.gapPrompt}>
            The {
                num === 0 ? 'first' :
                num === 1 ? 'second' :
                num === 2 ? 'third' : ''
            } gap is...
        </Typography>
        {unsolvedGaps.isLoading === false && unsolvedGaps.error === null && <SolutionGroup unsolvedGap={unsolvedGaps.data[num]} solutions={solutions.filter(sol => sol.clientGapId === unsolvedGaps.data[num].id)} />}
    </>
}

const Solutioning: React.FC = () => {
    const match = useRouteMatch()
    useStyles();
    return <div>
        <Switch>
            <Route path={`${match.path}/1`}>
                <Instructions />
            </Route>
            <Route path={`${match.path}/:number`}>
                <Prompt />
            </Route>
        </Switch>
    </div>
}

export default Solutioning;