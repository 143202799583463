import React from 'react'
import { Typography, makeStyles, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Box } from '@material-ui/core'
import { useSolutionsScores } from '../../../hooks/solutionScoreHooks';
import { useRouteMatch, Route, Switch } from 'react-router-dom';
import ScoreInput from './ScoreInput';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
    textBlock: {
        margin: theme.spacing(2, 0, 3),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1, 0, 2),
        }, 
    },
    gapPrompt: {
        margin: theme.spacing(2, 0, 1),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1, 0, 1),
        }, 
    },
    commentaryBlock: {
        marginBottom: theme.spacing(1),
        color: '#595959',
    },
    commentary: {
        margin: theme.spacing(-1, 0, 2),
        border: '.5px solid',
        borderColor: theme.palette.grey[100],
        padding: theme.spacing(0, 1)
    },
    moreInfo: {
        margin: theme.spacing(3,0),
    },
    summary: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontWeight: 600,
    },
    howToScore: {
        margin: theme.spacing(1.5,0,0),
    },
}))

const Instructions: React.FC = () => {
    const classes = useStyles();
    const {
        gapsForSolutionScoring: gapsForScoring
    } = useSolutionsScores();
    return <div>
        <Typography className={classes.textBlock}>
            Now, it’s time to evaluate our ideas for impact and feasibility! This is our chance to narrow in and be critical.
        </Typography>
        <Typography className={classes.textBlock}>
            You will be presented with three gaps and some proposed solutions for each. For each solution, use your imagination and best judgement to assign a rating for impact and feasibility from 1 (low) to 5 (high).
        </Typography>
        <ExpansionPanel className={classes.moreInfo}>
            <ExpansionPanelSummary className={classes.summary} expandIcon={<ExpandMoreIcon className={classes.summary} />}>
                <Typography variant="subtitle2">
                    How to score
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Box>
                    <Typography className={classes.commentaryBlock}>
                        A solution at level 1 impact would not positively affect the process at all. At level 5 impact, the proposed solution would dramatically and positively change the process of operationalizing responsible AI.
                    </Typography>
                    <Typography className={classes.commentaryBlock}>
                        A solution at level 1 feasibility would be so difficult to create or implement in the present political, economic, or technical setting that it should not be pursued. At level 5 feasibility, the proposed solution is readily doable and easy to accomplish. It may even already exist or be implemented. (Please share examples!)
                    </Typography>
                </Box>
            </ExpansionPanelDetails>
        </ExpansionPanel>
        <Typography className={classes.gapPrompt}>
            For the gap...
        </Typography>
        {gapsForScoring?.isLoading === false && !gapsForScoring.error && <ScoreInput gapForScoring={gapsForScoring.data[0]} />}
    </div>
}

const Prompt: React.FC = () => {
    const match = useRouteMatch<{number: string}>()
    const classes = useStyles();
    const num = Number(match.params.number) - 1;
    const {
        gapsForSolutionScoring: gapsForScoring
    } = useSolutionsScores();

    if (!gapsForScoring || gapsForScoring.isLoading) {
        return <div>Loading...</div> // TODO: better loading UI
    }

    if (gapsForScoring.error) {
        return <div>Error</div>
    }

    return <>
        <Typography className={classes.gapPrompt}>
            The  {
                num === 0 ? 'first' :
                num === 1 ? 'second' : 
                num === 2 ? 'third' : ''
            } gap is...
        </Typography>

        <ScoreInput gapForScoring={gapsForScoring.data[num]} />
    </>
}

const Scoring: React.FC = () => {
    const match = useRouteMatch()
    return <>
        <Switch>
            <Route path={`${match.path}/1`}>
                <Instructions />
            </Route>
            <Route path={`${match.path}/:number`}>
                <Prompt />
            </Route>
        </Switch>
    </>
}

export default Scoring;