import React, { useEffect, useRef } from 'react'
import { makeStyles, Typography, useTheme, Box, Button, useMediaQuery } from '@material-ui/core'
import SVGimage from './img/ClosingGapsHeaderFolks3.svg'
import SVGmobileimage from './img/ClosingGapsMobileHeaderFolks.svg'
import { initialize } from './js/closinggapsheader'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
    headerOverlay: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        left: 0,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    svgMainObject: {
        width: '100%',
        height: 'auto', 
        padding: 0, 
        margin: 0,
        opacity: 0, 
        transition: 'opacity 2s',
        marginBottom: '-18rem',
        marginTop: '-1rem',
        visibility: 'visible',
        willChange: 'transform',
        [theme.breakpoints.down('sm')]: {
            visibility: 'hidden',
        },
    },
    svgMobileObject: {
        width: '100%',
        height: 'auto', 
        padding: 0, 
        margin: 0,
        marginBottom: '-2rem',
        opacity: 0, 
        transition: 'opacity 2s',
        marginTop: '-1rem',
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    headerBox: {
        //backgroundColor: 'blue',
    },
    titleBox: {
        maxWidth: '30rem',
        //backgroundColor: 'green',
        margin: 'auto',
        //paddingTop: '.5rem',
    },
    preTitle: {
        color: theme.palette.secondary.main,
        fontSize: '.75rem',
        paddingBottom: '.5rem',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 0,
            paddingTop: '.5rem',
            display: 'none',
        },
    },
    mainTitle: {
        color: 'black',
        fontSize: '3rem',
        fontWeight: 600,
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '2.25rem',
        },
    },
    subTitle: {
        color: theme.palette.secondary.main,
        fontSize: '1.5rem',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
    },
    postTitle: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main,
        fontSize: '.75rem',
        textAlign: 'center',
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            padding: '.25rem',
            display: 'block',
            width: '100%',
            margin: '.25rem 0',
        },
    },
    welcomeNav: {
        display: 'flex',
        margin: 'auto',
        maxWidth: '30rem',
        justifyContent: 'space-between',
        padding: '1rem 0 0',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            padding: '.5rem 0 0',
            justifyContent: 'space-around',
        },
    },
    mainButton: {
        flexBasis: '12rem',
        color: theme.palette.secondary.contrastText,
        borderRadius: '1rem',
        fontFamily: 'Roboto Mono, monospace',
        fontWeight: 500,
        textTransform: 'uppercase',
        fontSize: '.75rem',
        [theme.breakpoints.down('sm')]: {
            flexBasis: '70%',
            maxWidth: '14rem',
            margin: '.5rem',
            align: 'center',
            order: 2,
        },
    },
    sideButton: {
        flexBasis: '8rem',
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.light,
        '&:hover': {
            backgroundColor: '#b5267e',
        },
        '&:active': {
            backgroundColor: '#b5267e',
        },
        borderRadius: '1rem',
        fontFamily: 'Roboto Mono, monospace',
        fontWeight: 500,
        textTransform: 'uppercase',
        fontSize: '.75rem',
        [theme.breakpoints.down('sm')]: {
            flexBasis: '45%',
            maxWidth: '10rem',
            margin: '.25rem',
            order: 1,
        },
    },
    break: {
        height: 0,
        order: 4,
        flexBasis: '0%',
        [theme.breakpoints.down('sm')]: {
            flexBasis: '100%',
            order: 2,
        },
    },
}))

type Props = {
}

const WelcomeHeader: React.FC<Props> = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const history = useHistory();
    const ref = useRef<HTMLObjectElement>(null)
    useEffect(() => {
        if (ref.current !== null) {
            ref.current.addEventListener('load', () => {
                // TODO: remove calls to document.getElementBy('svgHeader') from closinggapsheader.js and simply pass down ref.current
                initialize();
            })
        }
        return () => {
        }
    }, [ref]);

    return <>
        <div id="headerOverlay" className={classes.headerOverlay} ></div>
        <Box className={classes.headerBox}>
            <object ref={ref} id="svgHeader" className={mobile ? classes.svgMobileObject : classes.svgMainObject} type="image/svg+xml" data={mobile ? SVGmobileimage : SVGimage}></object>
            <Box className={classes.titleBox}>
                <Typography variant="subtitle2" className={classes.preTitle}>10-Minute Ideation from Partnership on AI</Typography>
                <Typography variant="h1" className={classes.mainTitle}>Closing Gaps <br></br>in Responsible AI</Typography>
                <Typography variant="subtitle1" className={classes.subTitle}>collectively turning principles into practice</Typography>
            </Box>
            <Typography variant="subtitle2" className={classes.postTitle}>10-Minute Ideation from Partnership on AI</Typography>
            <Box className={classes.welcomeNav}>
                <Button className={classes.sideButton}  variant="contained" href="https://www.partnershiponai.org/pai-launches-interactive-project-to-put-ethical-ai-principles-into-practice/">Background</Button>
                <Button className={classes.mainButton} variant="contained" color="primary" onClick={() => history.push("/game/spotting-gaps")}>Start the ideation!</Button>
                <div className={classes.break} />
                <Button className={classes.sideButton}  variant="contained" href="https://www.partnershiponai.org/about/">About PAI</Button>
                
            </Box>
        </Box>

     </>
}

export default WelcomeHeader;