import React from 'react';
import { GapWithSolutions } from "../../../types";
import GapDisplay from '../../GapDisplay';
import { makeStyles, Typography, Box } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import { useSolutionsScores } from '../../../hooks/solutionScoreHooks';

type Props = {
    gapForScoring: GapWithSolutions
}

const useStyles = makeStyles(theme => ({
    currentGap: {
        margin: theme.spacing(0,0,3,2),
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(0),
        },
    },
    solutionStyle: {
        margin: theme.spacing(.5, 0, .5, 2),
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(1),
        },
        fontWeight: 500,
    },
    ratingGroup: {
        marginBottom: theme.spacing(2),
        display: 'flex',
        flexWrap: 'wrap',
    },
    impact: {
        marginRight: theme.spacing(7.5),
    },
    feasibility:{
        
    },
    ratingField: {
        color: theme.palette.secondary.light,
    },
}))


const ScoreInput: React.FC<Props> = (props) => {
    const { gapForScoring } = props
    const classes = useStyles(props)
    const { solutionScores, updateFields } = useSolutionsScores();

    /*const num = 3;
    {
        num === 0 ? 'first' :
        num === 1 ? 'second' :
        num === 2 ? 'third' : ''
    } // taken from the Solutioning index*/

    if (solutionScores.filter(score => gapForScoring.fields.Solutions.find(solution => score.clientSolutionId === solution.id))?.find(score => score.isLoading === true)) {
        return <>Loading</>
    }

    return <>
        <Box className={classes.currentGap}>
            <GapDisplay gap={gapForScoring} />
        </Box>
        {gapForScoring.fields.Solutions.map(solution => {
            const score = solutionScores.find(s => s.clientSolutionId === solution.id) 
            return <Box key={solution.id}>
                <Typography>Solution:</Typography> {/* Would be nice to number */} 
                <Typography className={classes.solutionStyle}>
                    {solution.fields.Solution}
                </Typography>
                <Box className={classes.ratingGroup}>
                    <Box className={classes.impact}>
                        <Typography>Impact</Typography>
                        <Rating
                            name={`ranking-solution-impact-${solution.id}`}
                            value={score?.data?.fields.Impact || 0}
                            size="large"
                            className={classes.ratingField}
                            onChange={(e, val) => updateFields(score?.clientId!, solution.id, {
                                Impact: val!
                            })}
                        /> {/* Error message about this above: Type 'number | null' is not assignable to type 'number | undefined'.
                    Type 'null' is not assignable to type 'number | undefined'.  TS2322 */}
                    </Box>
                    <Box className={classes.feasibility}>
                        <Typography>Feasibility</Typography>
                        <Rating
                            name={`ranking-solution-feasibility-${solution.id}`}
                            value={score?.data?.fields.Feasibility || 0}
                            size="large"
                            className={classes.ratingField}
                            onChange={(e, val) => updateFields(score?.clientId!, solution.id, {
                                Feasibility: val!
                            })}
                        />
                    </Box>
                </Box>
            </Box>
        })}
    </>
}

export default ScoreInput