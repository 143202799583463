import React from 'react';
import SpotAndScore from './SpotAndScore';

const SpottingGaps: React.FC = () => {
    return <div>
        {/*<Route path={`${match.path}/identification`}>
            <Identification />
        </Route>
        <Route path={`${match.path}/prioritization`}>
            <Prioritization />
        </Route>*/}
        <SpotAndScore />
    </div>
}

export default SpottingGaps;