import { debounce } from "debounce";
import { PlayerInfo } from "../types";
import fetchJson from "../utils/data";
import { useSurvey } from "../context/SurveyContext";
import { useCallback } from "react";

const updatePlayer = debounce((playerId: string, playerInfo: PlayerInfo) => {
    fetchJson(`/api/playerInfo/?playerId=${playerId}`, 'PATCH', {
        ...playerInfo
    })
}, 200)

export function useMultipleChoice(id: string) {
    const { player } = useSurvey();

    const setMultipleChoiceAnswer = useCallback(async (value: string) => {
        updatePlayer(player.id, {
            ...player,
            fields: {
                ...player.fields,
                [id]: value
            }
        })
    }, [player, id])

    return {
        setMultipleChoiceAnswer
    }
}