import React, { useContext, useState, useRef, useCallback } from 'react'
import { Typography, makeStyles, Button, TextField, Box, Link } from '@material-ui/core'
import Counter from '../Counter';
import { GlobalDataContext } from '../../../context/GlobalDataContext';
import KeepPlaying, { Activity } from './KeepPlaying';
import { useSurvey, SurveyContextState } from '../../../context/SurveyContext';
import theme from '../../../theme';
import SocialSharing from './socialSharing';

interface Props {

}

const useStyles = makeStyles(theme => ({
    options: {
        //padding: theme.spacing(2, 0),
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '15rem',
        margin: '0 auto',
        marginTop: theme.spacing(2),
        '& > *': {
            margin: theme.spacing(0, 0, 2, 0),
            padding: theme.spacing(1),
        }
    },
    textBlock: {
        margin: theme.spacing(2, 0, 3),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1, 0, 2),
        },
    },
    emBlock: {
        margin: theme.spacing(2, 0, 1),
        fontWeight: 600,
    },
    subtextTitle: {
        margin: theme.spacing(1, 0),
        color: '#595959',
        fontWeight: 600,
    },
    subtextBlock: {
        margin: theme.spacing(1, 0),
        color: '#595959',
    },
    highlight: {
        color: theme.palette.secondary.main,
        fontWeight: 'bold',
    },
    interactiveBlock: {
        width: '100%',
        maxWidth: theme.breakpoints.values.sm,
        margin: '0 auto',
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'space-around',
    },
    horizontalLine: {
        margin: theme.spacing(3, 0, 3),
        border: '.5px solid #C4C4C4',
    },
    a: { textDecoration: 'none', },
    pillButton: {
        fontFamily: 'Roboto Mono, monospace',
        textTransform: 'uppercase',
        borderRadius: '1.25rem',
    },
}))

const Finish: React.FC<Props> = () => {
    const classes = useStyles();
    const inputRef = useRef<HTMLInputElement>();
    const { counts, hiddenSurveyItems, setHiddenSurveyItems } = useContext(GlobalDataContext)
    const surveyContext = useSurvey();
    const [ activity, setActivity ] = useState<Activity>()
    const playerGaps = [...surveyContext.gaps, ...hiddenSurveyItems.gaps].filter(g => g.data?.fields.Need && g.data.fields?.Challenge).length
    const playerSolutions = [...surveyContext.solutions, ...hiddenSurveyItems.solutions].filter(s => s.data?.fields.Solution).length
    const playerScores = [...surveyContext.solutionScores, ...hiddenSurveyItems.scores].filter(s => (s.data?.fields.Feasibility || 0) > 0 || (s.data?.fields.Impact || 0) > 0).length

    const handleKeepPlaying = useCallback((innerSurvey: SurveyContextState) => {
        setHiddenSurveyItems({
            gaps: [...hiddenSurveyItems.gaps, ...innerSurvey.gaps],
            solutions: [...hiddenSurveyItems.solutions, ...innerSurvey.solutions],
            scores: [...hiddenSurveyItems.scores, ...innerSurvey.solutionScores],
        })
        setActivity(undefined)
    }, [hiddenSurveyItems, setHiddenSurveyItems])

    const collectiveCounter = counts ?
        <Counter 
            counts={[
                {
                    label: 'Gaps',
                    value: counts.gaps + surveyContext.gaps.length
                },
                {
                    label: 'Solutions',
                    value: counts.solutions + surveyContext.solutions.length
                },
                {
                    label: 'Scores',
                    value: counts.scores + surveyContext.solutionScores.length
                }
            ]}
        /> : <>Loading</> // TODO: better loading?

    return <>
        <Typography className={classes.textBlock}>
            Thank you for participating in Closing Gaps!
        </Typography>
        <Typography className={classes.emBlock}>
        You just contributed <span className={classes.highlight}>{playerGaps}</span> gaps, <span className={classes.highlight}>{playerSolutions}</span> solutions, and <span className={classes.highlight}>{playerScores}</span> scores. 
        Together we generated:
        </Typography>
        {collectiveCounter}
        {/*{playerCounter}}*/}

        <Typography className={classes.emBlock}>
            Sign up for updates and a copy of the findings from this work:
        </Typography>
        <div className={classes.interactiveBlock}>
            <form style={{ width: '100%', marginTop: theme.spacing(-1) }} onSubmit={e => {
                e.preventDefault();
                const email = inputRef.current?.value || '';
                console.log(email)
                if (email) {
                    window.open(`https://www.tfaforms.com/4799641?tfa_2671=${encodeURI(email)}`)
                }
            }}>
                <Box marginBottom={1} width="100%">
                    <TextField inputRef={inputRef} type="email" label="Email" helperText="Separated from your responses to preserve anonymity" fullWidth />
                </Box>
                <Box>
                    <Button
                        variant={'outlined'}
                        size={'small'}
                        color="primary"
                        type="submit"
                        className={classes.pillButton}
                    >Sign Up</Button>
                </Box>
            </form>
        </div>
        <Typography className={classes.emBlock}>
            Share with your community and help put ethical tech principles into organizational practice:
        </Typography>
        <div className={classes.interactiveBlock}>
            <SocialSharing />
        </div>
        <Typography className={classes.emBlock}>This is (possibly) the end, or you can select one of the following options to keep playing!</Typography>
        <div className={classes.options}>
            <Button variant="contained" className={classes.pillButton} color="secondary" onClick={() => setActivity('gaps')}>Spot more gaps</Button>
            <Button variant="contained" className={classes.pillButton} color="secondary" onClick={() => setActivity('solutions')}>More Solutions</Button>
            <Button variant="contained" className={classes.pillButton} color="secondary" onClick={() => setActivity('scoring')}>More Scoring</Button>
        </div>
        {activity && <KeepPlaying activity={activity} onClose={handleKeepPlaying} />}
        <div className={classes.horizontalLine}></div>
        <Typography variant="body1" component="h2" className={classes.subtextTitle}>
            Acknowledgements
        </Typography>
        <Typography className={classes.subtextBlock}>
            PAI is deeply grateful to all of the many collaborators who made this work possible, in particular, participants in the All Partners Meeting workshop and Closing Gaps project team for their suggestions and wisdom.
        </Typography>
        <Typography className={classes.subtextBlock}>
            PAI is additionally grateful to <Link className={classes.a} href="https://www.le3.io/" target="_blank" rel="noopener noreferrer">Desmond Lee</Link> without whose contributions to the design and web development of this ideation, this project would not have been possible.
        </Typography>
    </>
}

export default Finish;