import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import CountUp from 'react-countup';

const useStyles = makeStyles(theme => ({
    counter: {
        display: 'flex',
        justifyContent: 'space-evenly',
        maxWidth: theme.breakpoints.values.sm,
        margin: '0 auto',
    },
    value: {
        color: theme.palette.primary.main,
    },
    label: {
        fontFamily: 'Roboto Mono, monospace',
        textTransform: 'uppercase',
        fontWeight: 500,
    },
    count: {
        textAlign: 'center',
        padding: theme.spacing(0, 2)
    }
}))

type Count = {
    value: number;
    label: string
}
type Props = {
    counts: Count[]
}

const CountDisplay: React.FC<Count & { className?: string }> = (props) => {
    const { label, value } = props
    const classes = useStyles();
    const countElement = React.useRef<HTMLElement>(null);

    return <div className={clsx(props.className, classes.count)}>
        <Typography
            variant="h2"
            className={classes.value}
            ref={countElement}
        >
            <CountUp
                start={Math.min(value * .8, 0)}
                end={value}
                duration={1}
            />
        </Typography>
        <Typography
            className={classes.label}
        >
            {label}
        </Typography>
    </div>
}

const Counter: React.FC<Props> = (props) => {
    const { counts } = props;
    const classes = useStyles();
    return <div className={classes.counter}>
        {counts.map(count => <CountDisplay key={count.label} {...count} />)}
    </div>
}

export default Counter;