type Method = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE'
async function fetchJson<T>(url: string, method: Method, body?: any) {
    console.log(url)
    const response = await fetch(url, {
        method,
        body: body ? JSON.stringify(body) : undefined,
        headers: {
            'Content-Type': 'application/json'
        }
    })
    const success = response.status >= 200 || response.status < 400;
    if (success) {
        return await response.json() as T;
    }
    else {
        throw new Error(response.statusText)
    }
}

export default fetchJson