import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import ReactWordcloud, { OptionsProp } from 'react-wordcloud';
import { GlobalDataContext } from '../../../context/GlobalDataContext';

interface Props {

}

const useStyles = makeStyles(theme => ({
  wordCloud: {
    margin: theme.spacing(-1, 0, 0),
    '& > div': {
      [theme.breakpoints.down('sm')]: { // the wordCloud seems to ignore this... perhaps it computes before we have the chance to check?
        height: 150
      },
    }
  },
}))

const cloudOptions = { // Something is failing here, and I don't know why
  //colors: [ '#892a8d', '#3c4fdd', '#2c2f79', '#ec1fba', '#db7769', '#0f1385'], // tried to use a mix of official colors, but it looks nasty
  /*colors: [
      '#1f2891', // theme.palette.primary.main dark blue *
      '#842387', // theme.palette.secondary.main purple
      '#CA3F54', // peachy, not from PAI palette but darkened *
      '#4C5CA6', // purpleish from PAI palette *
      '#C84194', // pink, not from PAI palette but darkened barely
      '#0C7CAD', // teal, not from PAI palette but darkened
      '#3c4fdd', // electric blue
    ],*/
  colors: ['#842387', '#CA3F54', '#C84194', '#2B2C7B', '#4C5CA6',],

  enableTooltip: false,
  deterministic: false,
  fontStyle: 'normal',
  fontWeight: 'normal',
  padding: 1,
  rotations: 3,
  fontSizes: [15, 55],
  rotationAngles: [0, -90],
  scale: 'sqrt',
  spiral: 'archimedean',
  transitionDuration: 0,
  fontFamily: [
    'Avenir',
    'Avenir Next',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','), // it may be better to use Roboto Mono or something else
} as OptionsProp;

const WordCloud: React.FC<Props> = () => {
  const classes = useStyles();
  const { words } = useContext(GlobalDataContext);

  return <>
    <div className={classes.wordCloud}> {/* For some reason, the svg disobeys the height*/}
      <ReactWordcloud options={cloudOptions} words={words || []} />
    </div>
  </>
};

export default React.memo(WordCloud);