import { useSurvey } from "../context/SurveyContext";
import { useEffect, useCallback } from "react";
import uuidv4 from 'uuid/v4'
import fetchJson from "../utils/data";
import { GapWithSolutions, SolutionScore } from "../types";
import { debounce } from "debounce";

const updateScore = debounce((playerId: string, solutionId: string, solution: Partial<SolutionScore>) =>
    fetchJson(`/api/solutions/${solutionId}/scores?playerId=${playerId}`, 'PATCH', {
        ...solution
    }), 200)

export function useSolutionsScores(numGaps = 3) {
    const survey = useSurvey();
    const dispatch = survey.dispatch

    const addSolutionScore = useCallback(async (solutionId: string) => {
        const clientId = uuidv4()

        dispatch({
            type: 'ADD_SOLUTION_SCORE',
            payload: {
                clientId,
                clientSolutionId: solutionId,
                isLoading: true,
                error: null,
                data: null
            }
        })

        const solution = await fetchJson<SolutionScore>(`/api/solutions/${solutionId}/scores?playerId=${survey.player.id}`, 'POST', {
            fields: {
            }
        } as Partial<SolutionScore>)

        dispatch({
            type: 'UPDATE',
            payload: {
                clientId,
                clientSolutionId: solutionId,
                isLoading: false,
                error: null,
                data: solution
            }
        })
    }, [dispatch, survey.player.id])


    const updateFields = useCallback(async (clientId: string, solutionId: string, fields: Partial<SolutionScore['fields']>) => {
        const existingScore = survey.solutionScores.find(s => s.clientId === clientId);
        if (!existingScore || existingScore.isLoading || !existingScore.data) {
            throw new Error("Solution score doesn't exist????")
        }

        const data = {
            ...existingScore.data,
            fields: {
                ...existingScore.data.fields,
                ...fields
            }
        }

        dispatch({
            type: 'UPDATE',
            payload: {
                ...existingScore,
                data
            }
        })

        await updateScore(survey.player.id, solutionId, {
            ...existingScore.data,
            ...data
        })
    }, [dispatch, survey.player.id, survey.solutionScores])

    useEffect(() => {
        async function fetchGapsForScoring() {
            const clientId = uuidv4()
            
            dispatch({
                type: 'SET_GAPS_FOR_SOLUTION_SCORING',
                payload: {
                    clientId,
                    isLoading: true as true,
                    error: null,
                    data: null
                }
            })

            const gaps = await fetchJson<GapWithSolutions[]>(`/api/randomGap?amount=${numGaps}&solutions=true&minSolutions=${3}&maxSolutions=${3}`, 'GET')

            gaps.forEach(gap => {
                gap.fields.Solutions.forEach(solution => {
                    addSolutionScore(solution.id)
                })
            })

            dispatch({
                type: 'SET_GAPS_FOR_SOLUTION_SCORING',
                payload: {
                    clientId,
                    isLoading: false as false,
                    error: null,
                    data: gaps
                }
            })
        }

        if (!survey.gapsForSolutionScoring) {
            fetchGapsForScoring()
        }
    })

    return {
        gapsForSolutionScoring: survey.gapsForSolutionScoring,
        solutionScores: survey.solutionScores,
        updateFields
    }
}