import React, { useContext } from 'react'
import { createContext, Reducer, useReducer } from "react";
import { PlayerInfo, Gap, LoadingState, Solution, GapWithSolutions, SolutionScore, GapScore } from "../types";

export type ClientGap = LoadingState<Gap>
export type ClientSolution = LoadingState<Solution> & { clientGapId: string }
export type ClientSolutionScore = LoadingState<SolutionScore> & { clientSolutionId: string }
export type ClientGapScore = LoadingState<GapScore> & { clientGapId: string }

export type SurveyContextState = {
    player: PlayerInfo
    gaps: ClientGap[] // identified gaps
    unsolvedGaps?: LoadingState<Gap[]> // gaps to find solutions for
    solutions: ClientSolution[]
    solutionScores: ClientSolutionScore[]
    gapScores: ClientGapScore[]
    dispatch: React.Dispatch<Action>
    gapsForSolutionScoring?: LoadingState<GapWithSolutions[]> // gaps to find solution scores for
    gapsForGapScoring?: LoadingState<Gap[]> // gaps to find gap scores for
}
export type SurveyContextActions = {
}
export type SurveyContextType = SurveyContextState & SurveyContextActions;

const initialState = {
    dispatch: () => { },
    player: null as any,
    gaps: [],
    solutions: [],
    solutionScores: [],
    gapScores: []
}
export const SurveyContext = createContext<SurveyContextType>(initialState)

type Action = {
    type: 'ADD_GAP',
    payload: ClientGap
} | {
    type: 'ADD_GAP_SCORE',
    payload: ClientGapScore
} | {
    type: 'SET_UNSOLVED_GAPS',
    payload: LoadingState<Gap[]>
} | {
    type: 'ADD_SOLUTION',
    payload: ClientSolution
} | {
    type: 'ADD_SOLUTION_SCORE',
    payload: ClientSolutionScore
} | {
    type: 'SET_GAPS_FOR_GAP_SCORING',
    payload: LoadingState<Gap[]>
} | {
    type: 'SET_GAPS_FOR_SOLUTION_SCORING',
    payload: LoadingState<GapWithSolutions[]>
} | {
    type: 'UPDATE'
    payload: any & LoadingState<any> // TODO: fix types
} | {
    type: 'DELETE',
    clientId: string
}

type SurveyReducer = Reducer<SurveyContextState, Action>
const surveyReducer: SurveyReducer = (state, action) => { 
    switch (action.type) {
        case 'ADD_GAP': 
            return {
                ...state,
                gaps: [
                    ...state.gaps,
                    action.payload
                ]
            } as SurveyContextState
        case 'ADD_GAP_SCORE': 
            return {
                ...state,
                gapScores: [
                    ...state.gapScores,
                    action.payload
                ]
            } as SurveyContextState
        case 'ADD_SOLUTION': 
            return {
                ...state,
                solutions: [
                    ...state.solutions,
                    action.payload
                ]
            } as SurveyContextState
        case 'ADD_SOLUTION_SCORE':
            return {
                ...state,
                solutionScores: [
                    ...state.solutionScores,
                    action.payload
                ]
            }
        case 'SET_GAPS_FOR_GAP_SCORING':
            return {
                ...state,
                gapsForGapScoring: action.payload
            }
        case 'SET_GAPS_FOR_SOLUTION_SCORING':
            return {
                ...state,
                gapsForSolutionScoring: action.payload
            }
        case 'SET_UNSOLVED_GAPS': 
            return {
                ...state,
                unsolvedGaps: action.payload
            } as SurveyContextState
        case 'UPDATE':
            return {
                ...state,
                gaps: state.gaps.map(e =>
                    e.clientId === action.payload.clientId ? action.payload : e
                ),
                gapScores: state.gapScores.map(e => 
                    e.clientId === action.payload.clientId ? action.payload : e
                ),
                solutions: state.solutions.map(e =>
                    e.clientId === action.payload.clientId ? action.payload : e
                ),
                solutionScores: state.solutionScores.map(e =>
                    e.clientId === action.payload.clientId ? action.payload : e
                )
            } as SurveyContextState
        case 'DELETE': 
            return {
                ...state,
                gaps: state.gaps.filter(e => e.clientId !== action.clientId),
                solutions: state.solutions.filter(e => e.clientId !== action.clientId),
                solutionScores: state.solutionScores.filter(e => e.clientId !== action.clientId)
            } as SurveyContextState
        default:
            throw new Error(`Unknown action type`)
    }
}

export function useSurvey() {
    return useContext(SurveyContext)
}

type ProviderProps = {
    player: PlayerInfo
}
export const SurveyProvider: React.FC<ProviderProps> = ({children, player}) => {
    const [state, dispatch] = useReducer(surveyReducer, initialState);
    if (window) {
        (window as any).surveyContext = state;
    }
    return <SurveyContext.Provider value={{...state, player, dispatch}}>
        {children}
    </SurveyContext.Provider>
}