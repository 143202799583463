import { useSurvey } from "../context/SurveyContext";
import { useEffect, useCallback } from "react";
import uuidv4 from 'uuid/v4'
import fetchJson from "../utils/data";
import { GapScore, Gap } from "../types";
import { debounce } from "debounce";

const updateScore = debounce((playerId: string, gapId: string, gapScore: Partial<GapScore>) =>
    fetchJson(`/api/gaps/${gapId}/scores?playerId=${playerId}`, 'PATCH', {
        ...gapScore
    }), 200)

export function useGapScores(numGaps = 5) {
    const survey = useSurvey();
    const dispatch = survey.dispatch

    const addGapScore = useCallback(async (gapId: string) => {
        const clientId = uuidv4()

        dispatch({
            type: 'ADD_GAP_SCORE',
            payload: {
                clientId,
                clientGapId: gapId,
                isLoading: true,
                error: null,
                data: null
            }
        })

        const gap = await fetchJson<GapScore>(`/api/gaps/${gapId}/scores?playerId=${survey.player.id}`, 'POST', {
            fields: {
            }
        } as Partial<GapScore>)

        dispatch({
            type: 'UPDATE',
            payload: {
                clientId,
                clientGapId: gapId,
                isLoading: false,
                error: null,
                data: gap
            }
        })
    }, [dispatch, survey.player.id])


    const updateFields = useCallback(async (clientId: string, gapId: string, fields: Partial<GapScore['fields']>) => {
        const existingScore = survey.gapScores.find(s => s.clientId === clientId);
        if (!existingScore || existingScore.isLoading || !existingScore.data) {
            throw new Error("Gap score doesn't exist????")
        }

        const data = {
            ...existingScore.data,
            fields: {
                ...existingScore.data.fields,
                ...fields
            }
        }

        dispatch({
            type: 'UPDATE',
            payload: {
                ...existingScore,
                data
            }
        })

        await updateScore(survey.player.id, gapId, {
            ...existingScore.data,
            ...data
        })
    }, [dispatch, survey.player.id, survey.gapScores])

    useEffect(() => {
        async function fetchGapsForScoring() {
            const clientId = uuidv4()
            
            dispatch({
                type: 'SET_GAPS_FOR_GAP_SCORING',
                payload: {
                    clientId,
                    isLoading: true as true,
                    error: null,
                    data: null
                }
            })

            const gaps = await fetchJson<Gap[]>(`/api/randomGap?amount=${numGaps}`, 'GET')

            gaps.forEach(gap => {
                addGapScore(gap.id)
            })

            dispatch({
                type: 'SET_GAPS_FOR_GAP_SCORING',
                payload: {
                    clientId,
                    isLoading: false as false,
                    error: null,
                    data: gaps
                }
            })
        }

        if (!survey.gapsForGapScoring) {
            fetchGapsForScoring()
        }
    })

    return {
        gapsForGapScoring: survey.gapsForGapScoring,
        gapScores: survey.gapScores,
        updateFields
    }
}